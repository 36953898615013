import { createSlice } from "@reduxjs/toolkit";
const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: "",
    id: "",
  },
  reducers: {
    setTokenHandler(state, action) {
      state.token = action.payload;
    },
    setIdHandler(state, action) {
      state.id = action.payload;
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice;