import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import BackButton from "../../assets/logo/BackButton.svg";

const HomeAddressPrivacy = ({ handleClickSetThree, handlePrevious }) => {
  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (newSelection) => {
    if (newSelection !== null) {
      setSelectedOption(newSelection);
      handleClickSetThree(newSelection);
    }
  };

  const handleContinue = () => {
    // Logic to handle the next step (e.g., navigate to next page)
    // if (onNext) onNext();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        textAlign: "left",
        alignItems: "center",
        padding: { xs: "0", md: "5rem" },
        paddingTop: { xs: "1rem" },
        maxWidth: { xs: "450px", md: "900px" },
      }}
    >
      <Box>
        <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
          Want to keep your home address private?
        </Typography>

        {/* Subtitle */}
        <Typography
          variant="body1"
          sx={{
            fontSize: "1.2rem",
            color: "#5A5A5A",
            mb: 4,
            maxWidth: "870px",
            width: "100%",
          }}
        >
          Every UK company needs to have a registered address for official mail,
          and it’s visible to the public. To keep your home address private, you
          can use RiseFormations registered London office address instead.
          <br />
          We’ll take care of all your official mail, scanning and sending it to
          you the same day.
        </Typography>

        <Button
          variant="outlined"
          onClick={() => handleOptionChange("useRiseFormations")}
          sx={{
            border: "2px solid",
            borderColor:
              selectedOption === "useRiseFormations" ? "#10182C" : "#D1D1D1",
            borderRadius: "8px",
            padding: "0.9rem",
            fontSize: "1rem",
            // fontWeight: "bold",
            width: { xs: "19rem", md: "37rem" },
            color:
              selectedOption === "useRiseFormations" ? "#10182C" : "#5A5A5A",
            marginBottom: "1rem",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#181e54",
              color: "white",
            },
          }}
        >
          Yes, I will use RiseFormations Address (+£89 + VAT/year)
        </Button>
        <br />
        <Button
          variant="outlined"
          onClick={() => handleOptionChange("useOwnAddress")}
          sx={{
            border: "2px solid",
            borderColor:
              selectedOption === "useOwnAddress" ? "#10182C" : "#D1D1D1",
            borderRadius: "8px",
            padding: "0.9rem",
            fontSize: "1rem",
            // fontWeight: "bold",
            color: selectedOption === "useOwnAddress" ? "#10182C" : "#5A5A5A",
            textTransform: "none",
            width: { xs: "19rem", md: "37rem" },
            marginBottom: "2rem",
            "&:hover": {
              backgroundColor: "#181e54",
              color: "white",
            },
          }}
        >
          No, I’ll use my own address
        </Button>

        <Box display="flex">
          <Button
            onClick={handlePrevious}
            sx={{
              color: "#000",
              fontSize: "1rem",
              textTransform: "none",
              marginBottom: "0.75rem",
              marginRight: "0.75rem",
            }}
          >
            <img width={"70px"} src={BackButton} />
          </Button>

          {/* Continue Button */}
          {/* <Button
            variant="contained"
            sx={{
              backgroundColor: "#10182C",
              color: "#fff",
              borderRadius: "20px",
              // padding: "0.75rem 2rem",
              fontSize: "1.25rem",
              fontWeight: "bold",
              textTransform: "none",
              ":hover": {
                backgroundColor: "#5900CC",
              },
            }}
            onClick={() => handleClickSetThree(selectedOption)}
          >
            Continue
          </Button> */}
        </Box>
      </Box>
    </Box>
  );
};

export default HomeAddressPrivacy;
