import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  TextField,
  Select,
  MenuItem,
  Button,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  FormControl,
  Typography,
  Divider,
  InputLabel,
  Grid,
} from "@mui/material";
import {
  DirectorsApi,
  getDirectorsApi,
  editDirectorsApi,
} from "../../apiServices/services/OfficeServices";
import { getcountries } from "../../apiServices/services/CompanyServices";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { searchPersonDetails } from "../../apiServices/services/CheckoutServices";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format, parseISO, isValid } from "date-fns";
import { updateFormStatus } from "../../apiServices/services/ProfileServices";

const Directors = ({ onContinue, onBack }) => {
  const [formData, setFormData] = useState({
    shareHolders: [
      {
        position: "",
        firstName: "",
        middleName: "",
        surName: "",
        email: "",
        mobileNumber: "",
        addressLine1: "",
        addressLine2: "",
        town: "",
        region: "",
        postCode: "",
        country: "",
        dateOfBirth: "",
        firstIdentificationType: "",
        firstIdentificationAnswer: "",
        seconddentificationType: "",
        secondIdentificationAnswer: "",
        thirdIdentificationType: "",
        thirdIdentificationAnswer: "",
      },
    ],
    userId: localStorage.getItem("userId"),
    isFormCompleted: false,
  });

  const [searching, setSearching] = useState(false);
  const [searched, setSearched] = useState(false);
  const [personData, setPersonData] = useState({});
  const [userId, setUserId] = useState(0);
  const [countries, setCountries] = useState([]);
  const [personKnown, setPersonKnown] = useState("no");
  const [isPSC, setIsPSC] = useState(false);
  const [errors, setErrors] = React.useState({});
  const consentType = [
    "Mother’s Maiden name",
    "Father’s first name",
    "National Insurance Number",
    "Telephone Number",
    " Town of birth",
    "Passport number",
  ];

  const shareHoldingRef = useRef(null);

  const handleInputChange = (index, e) => {
    const { name, value } = e.target;

    if (name === "mobileNumber" && value.length > 13) {
      return;
    } else {
      setFormData((prevFormData) => {
        const updatedShareHolders = [...prevFormData.shareHolders];
        updatedShareHolders[index] = {
          ...updatedShareHolders[index],
          [name]: value,
        };

        return { ...prevFormData, shareHolders: updatedShareHolders };
      });
    }
  };

  const onCountryChange = (index, e) => {
    const selectedCountry = e.target.value;
    setFormData((prevState) => ({
      ...prevState,
      country: selectedCountry,
    }));
  };

  useEffect(() => {
    getcountries().then((response) => {
      const countryNames = response?.map((country) => country.name.common);
      const sortedCountryNames = countryNames.sort((a, b) =>
        a.localeCompare(b)
      );
      const index = sortedCountryNames.indexOf("United Kingdom");
      if (index !== -1) {
        sortedCountryNames.splice(index, 1);
      }
      sortedCountryNames.unshift("United Kingdom");
      setCountries(sortedCountryNames);
    });
    getDirectorsApi(formData.userId).then((response) => {
      if (response?.attributes?.results[0]) {
        setUserId(response?.attributes?.results[0]?.id);
        const shareholders = response.attributes?.results[0]?.shareHolders;
        const firstShareholder = shareholders[0];
        setFormData((prevState) => ({
          ...prevState,
          shareHolders: response.attributes.results[0].shareHolders,
          isFormCompleted: response.attributes.results[0].isFormCompleted,
        }));
      }
    });
  }, []);

  const handleDateChange = (index, value) => {
    setFormData((prevFormData) => {
      const updatedShareHolders = [...prevFormData.shareHolders];
      updatedShareHolders[index] = {
        ...updatedShareHolders[index],
        dateOfBirth: value,
      };

      return { ...prevFormData, shareHolders: updatedShareHolders };
    });
  };

  const handleCheckboxChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.checked });
  };

  const handleUsePerson = () => {};

  const handleSearch = () => {
    setSearching(true);
    setSearched(false);
    searchPersonDetails(formData.forename).then((res) => {
      if (res.status === 200) {
        setSearching(false);
        setSearched(true);
      }
    });
  };

  const getFilteredOptions = (index, type) => {
    const selectedTypes = formData.shareHolders.reduce((acc, shareholder) => {
      acc.push(
        shareholder.firstIdentificationType,
        shareholder.seconddentificationType,
        shareholder.thirdIdentificationType
      );
      return acc;
    }, []);

    return consentType.filter(
      (option) =>
        !selectedTypes.includes(option) ||
        formData.shareHolders[index][type] === option
    );
  };

  const getFilteredOptionsForOtherIndex = (index, type) => {
    const selectedTypes = [];
    selectedTypes.push(formData.shareHolders[index].firstIdentificationType);
    selectedTypes.push(formData.shareHolders[index].seconddentificationType);
    selectedTypes.push(formData.shareHolders[index].seconddentificationType);

    return consentType.filter(
      (option) =>
        !selectedTypes.includes(option) ||
        formData.shareHolders[index][type] === option
    );
  };

  const handleSubmit = () => {
    const newErrors = {};
    let isValid = true;

    formData.shareHolders.forEach((shareholder, index) => {
      const {
        position,
        firstName,
        surName,
        email,
        mobileNumber,
        addressLine1,
        addressLine2,
        postCode,
        country,
        dateOfBirth,
        firstIdentificationAnswer,
        firstIdentificationType,
        secondIdentificationAnswer,
        seconddentificationType,
        thirdIdentificationAnswer,
        thirdIdentificationType,
      } = shareholder;

      if (
        !position ||
        !firstName ||
        !surName ||
        !email ||
        !mobileNumber ||
        !addressLine1 ||
        !addressLine2 ||
        !postCode ||
        !country ||
        !dateOfBirth ||
        !firstIdentificationAnswer ||
        !firstIdentificationType ||
        !secondIdentificationAnswer ||
        !seconddentificationType ||
        !thirdIdentificationAnswer ||
        !thirdIdentificationType
      ) {
        newErrors[index] = {
          position: !position,
          firstName: !firstName,
          surName: !surName,
          email: !email,
          mobileNumber: !mobileNumber,
          addressLine1: !addressLine1,
          addressLine2: !addressLine2,
          postCode: !postCode,
          country: !country,
          dateOfBirth: !dateOfBirth,
          firstIdentificationAnswer: !firstIdentificationAnswer,
          firstIdentificationType: !firstIdentificationType,
          secondIdentificationAnswer: !secondIdentificationAnswer,
          seconddentificationType: !seconddentificationType,
          thirdIdentificationAnswer: !thirdIdentificationAnswer,
          thirdIdentificationType: !thirdIdentificationType,
        };
        isValid = false;
      }
    });

    if (!isValid) {
      setErrors(newErrors);
      alert("Please fill in all the required fields.");
      return;
    }

    setErrors({});
    if (!formData.isFormCompleted) {
      DirectorsApi({ ...formData, isFormCompleted: true }).then((res) => {
        if (res.status === 200) {
          updateFormStatus(formData.userId, {
            isDirectorDetailsCompleted: true,
          }).then((res) => {
            if (res.status === 200) {
              onContinue();
              localStorage.setItem("activeForm", "isDirectorDetailsCompleted");
            } else {
            }
          });
        }
      });
    } else {
      editDirectorsApi(userId, { ...formData }).then((res) => {
        if (res.status === 200) {
          onContinue();
          localStorage.setItem("activeForm", "isDirectorDetailsCompleted");
        }
      });
    }
  };

  const handleAddDirector = () => {
    const newDirector = {
      position: "",
      firstName: "",
      middleName: "",
      surName: "",
      email: "",
      mobileNumber: "",
      addressLine1: "",
      addressLine2: "",
      town: "",
      region: "",
      postCode: "",
      country: "",
      dateOfBirth: "",
      firstIdentificationType: "",
      firstIdentificationAnswer: "",
      seconddentificationType: "",
      secondIdentificationAnswer: "",
      thirdIdentificationType: "",
      thirdIdentificationAnswer: "",
    };
    setFormData((prevFormData) => {
      const updatedShareHolders = [...prevFormData.shareHolders, newDirector];
      return { ...prevFormData, shareHolders: updatedShareHolders };
    });
    setTimeout(() => {
      if (shareHoldingRef.current) {
        shareHoldingRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };

  const handleRemoveDirector = (index) => {
    setFormData((prev) => ({
      ...prev,
      shareHolders: prev.shareHolders.slice(0, -1),
    }));
  };

  return (
    <Box
      sx={{
        width: "100%",
        p: 3,
        borderRadius: 2,
        boxShadow: 3,
        bgcolor: "background.paper",
        margin: "0 auto",
      }}
    >
      <Box>
        <Typography
          variant="h6"
          sx={{
            color: "1C2434",
            fontWeight: "bold",
            fontSize: "1.25rem",
          }}
        >
          Director 1
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: "1rem",
            fontWeight: "medium",
            color: "#000000",
            mb: 1,
            mt: 2,
          }}
        >
          What will be your position in the company?
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: "1rem",
            color: "gray",
            mb: 2,
          }}
        >
          You must have at least 1 Director & shareholder to operate.​
        </Typography>{" "}
        <FormControl
          variant="outlined"
          fullWidth
          required
          sx={{ maxWidth: "550px", width: "100%" }}
        >
          <Select
            name="position"
            value={formData.shareHolders[0]["position"] || ""}
            onChange={(e) => handleInputChange(0, e)}
            displayEmpty
            renderValue={(selected) => {
              return (
                selected || (
                  <span style={{ color: "gray" }}>Select position</span>
                )
              );
            }}
            sx={{
              maxHeight: "400px",
              borderRadius: "0.5rem",
            }}
            error={!!errors[0]?.position}
            helperText={errors[0]?.position ? "This field is required" : ""}
          >
            <MenuItem
              key={"Director + Shareholder"}
              value={"Director + Shareholder"}
              sx={{
                fontSize: "1rem",
                fontWeight: "medium",
                color: "64748B",
              }}
            >
              Director + Shareholder
            </MenuItem>
            <MenuItem
              key={"Director Only"}
              value={"Director Only"}
              sx={{
                fontSize: "1rem",
                fontWeight: "medium",
                color: "64748B",
              }}
            >
              Director Only
            </MenuItem>
            <MenuItem
              key={"Shareholder Only"}
              value={"Shareholder Only"}
              sx={{
                fontSize: "1rem",
                fontWeight: "medium",
                color: "64748B",
              }}
            >
              Shareholder Only
            </MenuItem>
          </Select>
        </FormControl>
        <Typography
          variant="body1"
          sx={{
            fontSize: "1rem",
            fontWeight: "medium",
            color: "#2F2D3B",
            marginTop: "1.5rem",
            marginBottom: "0.5rem",
            maxWidth: "745px",
            width: "100%",
          }}
        >
          Please provide your details below. These will not be made publicly
          available. ​
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: "1rem",
            fontWeight: "medium",
            color: "2F2D3B",
            marginTop: "1.2rem",
            mb: 1,
          }}
        >
          First Name
        </Typography>
        <TextField
          name="firstName"
          fullWidth
          key="firstName"
          variant="outlined"
          value={formData.shareHolders[0]["firstName"] || ""}
          onChange={(e) => handleInputChange(0, e)}
          sx={{
            maxWidth: "555px",
            width: "100%",
          }}
          error={!!errors[0]?.firstName}
          helperText={errors[0]?.firstName ? "This field is required" : ""}
        />
        <Typography
          variant="body1"
          sx={{
            fontSize: "1rem",
            fontWeight: "medium",
            color: "2F2D3B",
            marginTop: "1.2rem",
            mb: 1,
          }}
        >
          Middle name
        </Typography>
        <TextField
          name="middleName"
          fullWidth
          key="middleName"
          variant="outlined"
          value={formData.shareHolders[0]["middleName"] || ""}
          onChange={(e) => handleInputChange(0, e)}
          sx={{ maxWidth: "555px", width: "100%" }}
          error={!!errors[0]?.middleName}
          helperText={errors[0]?.middleName ? "This field is required" : ""}
        />
        <Typography
          variant="body1"
          sx={{
            fontSize: "1rem",
            fontWeight: "medium",
            color: "2F2D3B",
            marginTop: "1.2rem",
            mb: 1,
          }}
        >
          Last Name
        </Typography>
        <TextField
          name="surName"
          fullWidth
          key="surName"
          variant="outlined"
          value={formData.shareHolders[0]["surName"] || ""}
          onChange={(e) => handleInputChange(0, e)}
          sx={{ maxWidth: "555px", width: "100%" }}
          error={!!errors[0]?.surName}
          helperText={errors[0]?.surName ? "This field is required" : ""}
        />
        <Typography
          variant="body1"
          sx={{
            fontSize: "1rem",
            fontWeight: "medium",
            color: "2F2D3B",
            marginTop: "1.2rem",
            mb: 1,
          }}
        >
          Email address
        </Typography>
        <TextField
          name="email"
          fullWidth
          key="email"
          variant="outlined"
          value={formData.shareHolders[0]["email"] || ""}
          onChange={(e) => handleInputChange(0, e)}
          sx={{ maxWidth: "555px", width: "100%" }}
          error={!!errors[0]?.email}
          helperText={errors[0]?.email ? "This field is required" : ""}
        />
        <Typography
          variant="body1"
          sx={{
            fontSize: "1rem",
            fontWeight: "medium",
            color: "2F2D3B",
            marginTop: "1.2rem",
            mb: 1,
          }}
        >
          Mobile number
        </Typography>
        <TextField
          name="mobileNumber"
          type="number"
          fullWidth
          key="mobileNumber"
          variant="outlined"
          value={formData.shareHolders[0]["mobileNumber"] || ""}
          onChange={(e) => handleInputChange(0, e)}
          sx={{ maxWidth: "555px", width: "100%" }}
          error={!!errors[0]?.mobileNumber}
          helperText={errors[0]?.mobileNumber ? "This field is required" : ""}
        />
        <Typography
          variant="body1"
          sx={{
            fontSize: "1rem",
            fontWeight: "bold",
            color: "#2F2D3B",
            marginTop: "2rem",
            marginBottom: "0.5rem",
            maxWidth: "745px",
            width: "100%",
          }}
        >
          Home Address
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: "1rem",
            fontWeight: "medium",
            color: "2F2D3B",
            marginTop: "1.2rem",
            mb: 1,
          }}
        >
          First line of address
        </Typography>
        <TextField
          name="addressLine1"
          fullWidth
          key="addressLine1"
          variant="outlined"
          value={formData.shareHolders[0]["addressLine1"] || ""}
          onChange={(e) => handleInputChange(0, e)}
          sx={{ maxWidth: "555px", width: "100%" }}
          error={!!errors[0]?.addressLine1}
          helperText={errors[0]?.addressLine1 ? "This field is required" : ""}
        />
        <Typography
          variant="body1"
          sx={{
            fontSize: "1rem",
            fontWeight: "medium",
            color: "2F2D3B",
            marginTop: "1.2rem",
            mb: 1,
          }}
        >
          Second line of address
        </Typography>
        <TextField
          name="addressLine2"
          fullWidth
          key="addressLine2"
          variant="outlined"
          value={formData.shareHolders[0]["addressLine2"] || ""}
          onChange={(e) => handleInputChange(0, e)}
          sx={{ maxWidth: "555px", width: "100%" }}
          error={!!errors[0]?.addressLine2}
          helperText={errors[0]?.addressLine2 ? "This field is required" : ""}
        />
        <Typography
          variant="body1"
          sx={{
            fontSize: "1rem",
            fontWeight: "medium",
            color: "2F2D3B",
            marginTop: "1.2rem",
            mb: 1,
          }}
        >
          Postcode
        </Typography>
        <TextField
          name="postCode"
          fullWidth
          key="postCode"
          variant="outlined"
          value={formData.shareHolders[0]["postCode"] || ""}
          onChange={(e) => handleInputChange(0, e)}
          sx={{ maxWidth: "555px", width: "100%" }}
          error={!!errors[0]?.postCode}
          helperText={errors[0]?.postCode ? "This field is required" : ""}
        />
        <Typography
          sx={{
            fontSize: "1rem",
            fontWeight: "medium",
            mb: 1,
            marginTop: "1.2rem",
          }}
        >
          Country
        </Typography>
        <FormControl
          variant="outlined"
          fullWidth
          required
          sx={{ maxWidth: "550px", width: "100%", mb: 1 }}
        >
          <Select
            name="country"
            value={formData.shareHolders[0]["country"] || ""}
            onChange={(e) => handleInputChange(0, e)}
            key="country"
            variant="outlined"
            sx={{ maxWidth: "555px", width: "100%" }}
            error={!!errors[0]?.country}
            helperText={errors[0]?.country ? "This field is required" : ""}
          >
            {countries?.map((country, index) => (
              <MenuItem
                key={index}
                value={country}
                sx={{
                  fontSize: "1rem",
                  fontWeight: "medium",
                  color: "64748B",
                }}
              >
                {country}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography
          variant="body1"
          sx={{
            fontSize: "1rem",
            fontWeight: "medium",
            color: "#000000",
            marginBottom: "1rem",
            marginTop: "1.2rem",
          }}
        >
          Date of Birth
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            name="dateOfBirth"
            value={formData.shareHolders[0]["dateOfBirth"] || null}
            onChange={(value) => handleDateChange(0, value)}
            inputFormat="dd/MM/yyyy"
            renderInput={(params) => (
              <TextField
                {...params}
                error={!!errors[0]?.dateOfBirth}
                helperText={
                  errors[0]?.dateOfBirth ? "This field is required" : ""
                }
              />
            )}
          />
        </LocalizationProvider>
        <Typography
          variant="body1"
          sx={{
            fontSize: "1rem",
            fontWeight: "bold",
            color: "#2F2D3B",
            marginTop: "2rem",
            marginBottom: "0.5rem",
            maxWidth: "745px",
            width: "100%",
          }}
        >
          Companies House Identification items​
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: "1rem",
            color: "#787878",
            mb: 2,
            maxWidth: "555px",
            width: "100%",
          }}
        >
          For security purposes, Companies House require you provide at least 3
          of the following identification responses: ​
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: "1rem",
            fontWeight: "medium",
            color: "#000000",
            mb: 1,
          }}
        >
          First identification item
        </Typography>
        <FormControl sx={{ maxWidth: "555px", width: "100%", mb: 1.5, mr: 10 }}>
          <InputLabel>Type</InputLabel>
          <Select
            value={formData.shareHolders[0].firstIdentificationType}
            onChange={(e) => handleInputChange(0, e)}
            label="Type"
            name="firstIdentificationType"
            error={!!errors[0]?.firstIdentificationType}
            helperText={
              errors[0]?.firstIdentificationType ? "This field is required" : ""
            }
          >
            {getFilteredOptions(0, "firstIdentificationType").map(
              (consentType) => (
                <MenuItem key={consentType} value={consentType}>
                  {consentType}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
        <Typography
          variant="body1"
          sx={{
            fontSize: "1rem",
            fontWeight: "medium",
            color: "#2F2D3B",
            maxWidth: "745px",
            width: "100%",
          }}
        >
          Answer
        </Typography>
        <TextField
          label="Answer"
          name="firstIdentificationAnswer"
          value={formData.shareHolders[0].firstIdentificationAnswer}
          onChange={(e) => handleInputChange(0, e)}
          error={!!errors[0]?.firstIdentificationAnswer}
          helperText={
            errors[0]?.firstIdentificationAnswer ? "This field is required" : ""
          }
          sx={{
            maxWidth: "555px",
            width: "100%",
            mb: "1.2rem",
            mt: "0.8rem",
          }}
        />
        <Typography
          variant="body1"
          sx={{
            fontSize: "1rem",
            fontWeight: "medium",
            color: "#000000",
            mb: 1,
          }}
        >
          Second identification item
        </Typography>
        <FormControl sx={{ maxWidth: "555px", width: "100%", mb: 1.5, mr: 10 }}>
          <InputLabel>Type</InputLabel>
          <Select
            value={formData.shareHolders[0].seconddentificationType}
            onChange={(e) => handleInputChange(0, e)}
            name="seconddentificationType"
            label="Type"
            error={!!errors[0]?.seconddentificationType}
            helperText={
              errors[0]?.seconddentificationType ? "This field is required" : ""
            }
          >
            {getFilteredOptions(0, "seconddentificationType").map(
              (consentType) => (
                <MenuItem key={consentType} value={consentType}>
                  {consentType}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
        <Typography
          variant="body1"
          sx={{
            fontSize: "1rem",
            fontWeight: "medium",
            color: "#2F2D3B",
            maxWidth: "745px",
            width: "100%",
          }}
        >
          Answer
        </Typography>
        <TextField
          label="Answer"
          name="secondIdentificationAnswer"
          value={formData.shareHolders[0].secondIdentificationAnswer}
          onChange={(e) => handleInputChange(0, e)}
          error={!!errors[0]?.secondIdentificationAnswer}
          helperText={
            errors[0]?.secondIdentificationAnswer
              ? "This field is required"
              : ""
          }
          sx={{
            maxWidth: "555px",
            width: "100%",
            mb: "1.2rem",
            mt: "0.8rem",
          }}
        />
        <Typography
          variant="body1"
          sx={{
            fontSize: "1rem",
            fontWeight: "medium",
            color: "#000000",
            mb: 1,
          }}
        >
          Third identification item
        </Typography>
        <FormControl sx={{ maxWidth: "555px", width: "100%", mb: 1.5, mr: 10 }}>
          <InputLabel>Type</InputLabel>
          <Select
            value={formData.shareHolders[0].thirdIdentificationType}
            onChange={(e) => handleInputChange(0, e)}
            label="Type"
            name="thirdIdentificationType"
            error={!!errors[0]?.thirdIdentificationType}
            helperText={
              errors[0]?.thirdIdentificationAnswer
                ? "This field is required"
                : ""
            }
          >
            {getFilteredOptions(0, "thirdIdentificationType").map(
              (consentType) => (
                <MenuItem key={consentType} value={consentType}>
                  {consentType}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
        <Typography
          variant="body1"
          sx={{
            fontSize: "1rem",
            fontWeight: "medium",
            color: "#2F2D3B",
            maxWidth: "745px",
            width: "100%",
          }}
        >
          Answer
        </Typography>
        <TextField
          label="Answer"
          name="thirdIdentificationAnswer"
          value={formData.shareHolders[0].thirdIdentificationAnswer}
          onChange={(e) => handleInputChange(0, e)}
          error={!!errors[0]?.thirdIdentificationAnswer}
          helperText={
            errors[0]?.thirdIdentificationAnswer ? "This field is required" : ""
          }
          sx={{
            maxWidth: "555px",
            width: "100%",
            mb: "1.2rem",
            mt: "0.8rem",
          }}
        />
      </Box>
      {formData.shareHolders.slice(1).map((shareholder, index) => (
        <Box key={index} ref={shareHoldingRef}>
          <Typography
            variant="h6"
            sx={{
              color: "1C2434",
              fontWeight: "bold",
              fontSize: "1.25rem",
            }}
          >
            Director {index + 2}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "1rem",
              fontWeight: "medium",
              color: "#000000",
              mb: 1,
              mt: 2,
            }}
          >
            What will be your position in the company?
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "1rem",
              color: "gray",
              mb: 2,
            }}
          >
            You must have at least 1 Director & shareholder to operate.​
          </Typography>{" "}
          <FormControl
            variant="outlined"
            fullWidth
            required
            sx={{ maxWidth: "550px", width: "100%" }}
          >
            <Select
              name="position"
              value={formData.shareHolders[index + 1]["position"] || ""}
              onChange={(e) => handleInputChange(index + 1, e)}
              displayEmpty
              renderValue={(selected) => {
                return (
                  selected || (
                    <span style={{ color: "gray" }}>Select position</span>
                  )
                );
              }}
              error={!!errors[index + 1]?.position}
              helperText={
                errors[index + 1]?.position ? "This field is required" : ""
              }
              sx={{ maxHeight: "400px", borderRadius: "0.5rem" }}
            >
              <MenuItem
                key={"Director + Shareholder"}
                value={"Director + Shareholder"}
                sx={{
                  fontSize: "1rem",
                  fontWeight: "medium",
                  color: "64748B",
                }}
              >
                Director + Shareholder
              </MenuItem>
              <MenuItem
                key={"Director Only"}
                value={"Director Only"}
                sx={{
                  fontSize: "1rem",
                  fontWeight: "medium",
                  color: "64748B",
                }}
              >
                Director Only
              </MenuItem>
              <MenuItem
                key={"Shareholder Only"}
                value={"Shareholder Only"}
                sx={{
                  fontSize: "1rem",
                  fontWeight: "medium",
                  color: "64748B",
                }}
              >
                Shareholder Only
              </MenuItem>
            </Select>
          </FormControl>
          {personKnown === "yes" && (
            <>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "1rem",
                  fontWeight: "medium",
                  color: "#000000",
                }}
              >
                Previous Forename(s)
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                name="previousForename"
                value={formData.previousForename || ""}
                onChange={(e) => handleInputChange(index + 1, e)}
                sx={{
                  maxWidth: "555px",
                  width: "100%",
                  mb: "1rem",
                  mt: "1rem",
                }}
              />{" "}
              <Typography
                variant="body1"
                sx={{
                  fontSize: "1rem",
                  fontWeight: "medium",
                  color: "#000000",
                }}
              >
                Previous Surnames
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                name="previousSurname"
                value={
                  formData.shareHolders[index + 1]["previousSurname"] || ""
                }
                onChange={(e) => handleInputChange(index + 1, e)}
                sx={{
                  maxWidth: "555px",
                  width: "100%",
                  mb: "2rem",
                  mt: "1rem",
                }}
              />
            </>
          )}
          <Typography
            variant="body1"
            sx={{
              fontSize: "1rem",
              fontWeight: "medium",
              color: "#2F2D3B",
              marginTop: "1.5rem",
              marginBottom: "0.5rem",
              maxWidth: "745px",
              width: "100%",
            }}
          >
            Please provide your details below. These will not be made publicly
            available. ​
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "1rem",
              fontWeight: "medium",
              color: "2F2D3B",
              marginTop: "1.2rem",
              mb: 1,
            }}
          >
            First Name
          </Typography>
          <TextField
            name="firstName"
            fullWidth
            key="firstName"
            variant="outlined"
            value={formData.shareHolders[index + 1]["firstName"] || ""}
            onChange={(e) => handleInputChange(index + 1, e)}
            sx={{ maxWidth: "555px", width: "100%" }}
            error={!!errors[index + 1]?.firstName}
            helperText={
              errors[index + 1]?.firstName ? "This field is required" : ""
            }
          />
          <Typography
            variant="body1"
            sx={{
              fontSize: "1rem",
              fontWeight: "medium",
              color: "2F2D3B",
              marginTop: "1.2rem",
              mb: 1,
            }}
          >
            Middle name
          </Typography>
          <TextField
            name="middleName"
            fullWidth
            key="middleName"
            variant="outlined"
            value={formData.shareHolders[index + 1]["middleName"] || ""}
            onChange={(e) => handleInputChange(index + 1, e)}
            sx={{ maxWidth: "555px", width: "100%" }}
            error={!!errors[index + 1]?.middleName}
            helperText={
              errors[index + 1]?.middleName ? "This field is required" : ""
            }
          />
          <Typography
            variant="body1"
            sx={{
              fontSize: "1rem",
              fontWeight: "medium",
              color: "2F2D3B",
              marginTop: "1.2rem",
              mb: 1,
            }}
          >
            Last Name
          </Typography>
          <TextField
            name="surName"
            fullWidth
            key="surName"
            variant="outlined"
            value={formData.shareHolders[index + 1]["surName"] || ""}
            onChange={(e) => handleInputChange(index + 1, e)}
            sx={{ maxWidth: "555px", width: "100%" }}
            error={!!errors[index + 1]?.surName}
            helperText={
              errors[index + 1]?.surName ? "This field is required" : ""
            }
          />
          <Typography
            variant="body1"
            sx={{
              fontSize: "1rem",
              fontWeight: "medium",
              color: "2F2D3B",
              marginTop: "1.2rem",
              mb: 1,
            }}
          >
            Email address
          </Typography>
          <TextField
            name="email"
            fullWidth
            key="email"
            variant="outlined"
            value={formData.shareHolders[index + 1]["email"] || ""}
            onChange={(e) => handleInputChange(index + 1, e)}
            sx={{ maxWidth: "555px", width: "100%" }}
            error={!!errors[index + 1]?.email}
            helperText={
              errors[index + 1]?.email ? "This field is required" : ""
            }
          />
          <Typography
            variant="body1"
            sx={{
              fontSize: "1rem",
              fontWeight: "medium",
              color: "2F2D3B",
              marginTop: "1.2rem",
              mb: 1,
            }}
          >
            Mobile number
          </Typography>
          <TextField
            name="mobileNumber"
            fullWidth
            key="mobileNumber"
            variant="outlined"
            value={formData.shareHolders[index + 1]["mobileNumber"] || ""}
            onChange={(e) => handleInputChange(index + 1, e)}
            sx={{ maxWidth: "555px", width: "100%" }}
            error={!!errors[index + 1]?.mobileNumber}
            helperText={
              errors[index + 1]?.mobileNumber ? "This field is required" : ""
            }
          />
          <Typography
            variant="body1"
            sx={{
              fontSize: "1rem",
              fontWeight: "bold",
              color: "#2F2D3B",
              marginTop: "2rem",
              marginBottom: "0.5rem",
              maxWidth: "745px",
              width: "100%",
            }}
          >
            Home Address
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "1rem",
              fontWeight: "medium",
              color: "2F2D3B",
              marginTop: "1.2rem",
              mb: 1,
            }}
          >
            First line of address
          </Typography>
          <TextField
            name="addressLine1"
            fullWidth
            key="addressLine1"
            variant="outlined"
            value={formData.shareHolders[index + 1]["addressLine1"] || ""}
            onChange={(e) => handleInputChange(index + 1, e)}
            sx={{ maxWidth: "555px", width: "100%" }}
            error={!!errors[index + 1]?.addressLine1}
            helperText={
              errors[index + 1]?.addressLine1 ? "This field is required" : ""
            }
          />
          <Typography
            variant="body1"
            sx={{
              fontSize: "1rem",
              fontWeight: "medium",
              color: "2F2D3B",
              marginTop: "1.2rem",
              mb: 1,
            }}
          >
            Second line of address
          </Typography>
          <TextField
            name="addressLine2"
            fullWidth
            key="addressLine2"
            variant="outlined"
            value={formData.shareHolders[index + 1]["addressLine2"] || ""}
            onChange={(e) => handleInputChange(index + 1, e)}
            sx={{ maxWidth: "555px", width: "100%" }}
            error={!!errors[index + 1]?.addressLine2}
            helperText={
              errors[index + 1]?.addressLine2 ? "This field is required" : ""
            }
          />
          <Typography
            variant="body1"
            sx={{
              fontSize: "1rem",
              fontWeight: "medium",
              color: "2F2D3B",
              marginTop: "1.2rem",
              mb: 1,
            }}
          >
            Postcode
          </Typography>
          <TextField
            name="postCode"
            fullWidth
            key="postCode"
            variant="outlined"
            value={formData.shareHolders[index + 1]["postCode"] || ""}
            onChange={(e) => handleInputChange(index + 1, e)}
            sx={{ maxWidth: "555px", width: "100%" }}
            error={!!errors[index + 1]?.postCode}
            helperText={
              errors[index + 1]?.postCode ? "This field is required" : ""
            }
          />
          <Typography
            sx={{
              fontSize: "1rem",
              fontWeight: "medium",
              mb: 1,
            }}
            gutterBottom
          >
            Country
          </Typography>
          <FormControl
            variant="outlined"
            fullWidth
            required
            sx={{ maxWidth: "550px", width: "100%", mb: 1 }}
          >
            <Select
              name="country"
              value={formData.shareHolders[index + 1]["country"] || ""}
              onChange={(e) => handleInputChange(index + 1, e)}
              key="country"
              variant="outlined"
              sx={{ maxWidth: "555px", width: "100%" }}
              error={!!errors[index + 1]?.country}
              helperText={
                errors[index + 1]?.country ? "This field is required" : ""
              }
            >
              {countries?.map((country, index) => (
                <MenuItem
                  key={index}
                  value={country}
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "medium",
                    color: "64748B",
                  }}
                >
                  {country}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography
            variant="body1"
            sx={{
              fontSize: "1rem",
              fontWeight: "medium",
              color: "#000000",
              marginBottom: "1rem",
              marginTop: "1.2rem",
            }}
          >
            Date of Birth
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              name="dateOfBirth"
              value={formData.shareHolders[index+1]["dateOfBirth"] || null}
              onChange={(value) => handleDateChange(index + 1, value)}
              inputFormat="dd/MM/yyyy"
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!errors[index]?.dateOfBirth} // Pass the error prop here
                  helperText={
                    errors[index]?.dateOfBirth ? "This field is required" : ""
                  } // Pass the helperText prop here
                />
              )}
            />
          </LocalizationProvider>
          <Typography
            variant="body1"
            sx={{
              fontSize: "1rem",
              fontWeight: "bold",
              color: "#2F2D3B",
              marginTop: "2rem",
              marginBottom: "0.5rem",
              maxWidth: "745px",
              width: "100%",
            }}
          >
            Companies House Identification items​
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "1rem",
              color: "#787878",
              mb: 2,
              maxWidth: "555px",
              width: "100%",
            }}
          >
            For security purposes, Companies House require you provide at least
            3 of the following identification responses: ​
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "1rem",
              fontWeight: "medium",
              color: "#000000",
              mb: 1,
            }}
          >
            First identification item
          </Typography>
          <FormControl
            sx={{ maxWidth: "555px", width: "100%", mb: 1.5, mr: 10 }}
          >
            <InputLabel>Type</InputLabel>
            {/* <Select
              value={
                formData.shareHolders[index + 1]["firstIdentificationType"]
              }
              onChange={(e) => handleInputChange(index + 1, e)} // Adjust index since slice starts from 1
              label="Type"
              name="firstIdentificationType"
            >
              {getFilteredOptions(index + 1, "firstIdentificationType").map(
                (consentType) => (
                  <MenuItem key={consentType} value={consentType}>
                    {consentType}
                  </MenuItem>
                )
              )}
            </Select> */}
            <Select
              value={shareholder.firstIdentificationType}
              onChange={(e) => handleInputChange(index + 1, e)} // Adjust index since slice starts from 1
              label="Type"
              name="firstIdentificationType"
              error={!!errors[index + 1]?.firstIdentificationType}
              helperText={
                errors[index + 1]?.firstIdentificationType
                  ? "This field is required"
                  : ""
              }
            >
              {getFilteredOptionsForOtherIndex(
                index + 1,
                "firstIdentificationType"
              ).map((consentType) => (
                <MenuItem key={consentType} value={consentType}>
                  {consentType}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography
            variant="body1"
            sx={{
              fontSize: "1rem",
              fontWeight: "medium",
              color: "#2F2D3B",
              maxWidth: "745px",
              width: "100%",
            }}
          >
            Answer
          </Typography>
          <TextField
            label="Answer"
            name="firstIdentificationAnswer"
            value={
              formData.shareHolders[index + 1]["firstIdentificationAnswer"]
            }
            error={!!errors[index + 1]?.firstIdentificationAnswer}
            helperText={
              errors[index + 1]?.firstIdentificationAnswer
                ? "This field is required"
                : ""
            }
            onChange={(e) => handleInputChange(index + 1, e)}
            sx={{
              maxWidth: "555px",
              width: "100%",
              mb: "1.2rem",
              mt: "0.8rem",
            }}
          />
          <Typography
            variant="body1"
            sx={{
              fontSize: "1rem",
              fontWeight: "medium",
              color: "#000000",
              mb: 1,
            }}
          >
            Second identification item
          </Typography>
          <FormControl
            sx={{ maxWidth: "555px", width: "100%", mb: 1.5, mr: 10 }}
          >
            <InputLabel>Type</InputLabel>
            {/* <Select
              value={
                formData.shareHolders[index + 1]["seconddentificationType"]
              }
              onChange={(e) => handleInputChange(index + 1, e)}
              label="Type"
              name="seconddentificationType"
            >
              {getFilteredOptions(index + 1, "seconddentificationType").map(
                (consentType) => (
                  <MenuItem key={consentType} value={consentType}>
                    {consentType}
                  </MenuItem>
                )
              )}
            </Select> */}
            <Select
              value={shareholder.seconddentificationType}
              onChange={(e) => handleInputChange(index + 1, e)} // Adjust index
              label="Type"
              error={!!errors[index + 1]?.seconddentificationType}
              helperText={
                errors[index + 1]?.seconddentificationType
                  ? "This field is required"
                  : ""
              }
              name="seconddentificationType"
            >
              {getFilteredOptionsForOtherIndex(
                index + 1,
                "seconddentificationType"
              ).map((consentType) => (
                <MenuItem key={consentType} value={consentType}>
                  {consentType}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography
            variant="body1"
            sx={{
              fontSize: "1rem",
              fontWeight: "medium",
              color: "#2F2D3B",
              maxWidth: "745px",
              width: "100%",
            }}
          >
            Answer
          </Typography>
          <TextField
            label="Answer"
            name="secondIdentificationAnswer"
            value={
              formData.shareHolders[index + 1]["secondIdentificationAnswer"]
            }
            onChange={(e) => handleInputChange(index + 1, e)}
            error={!!errors[index + 1]?.secondIdentificationAnswer}
            helperText={
              errors[index + 1]?.secondIdentificationAnswer
                ? "This field is required"
                : ""
            }
            sx={{
              maxWidth: "555px",
              width: "100%",
              mb: "1.2rem",
              mt: "0.8rem",
            }}
          />
          <Typography
            variant="body1"
            sx={{
              fontSize: "1rem",
              fontWeight: "medium",
              color: "#000000",
              mb: 1,
            }}
          >
            Third identification item
          </Typography>
          <FormControl
            sx={{ maxWidth: "555px", width: "100%", mb: 1.5, mr: 10 }}
          >
            <InputLabel>Type</InputLabel>
            {/* <Select
              name="thirdIdentificationType"
              value={
                formData.shareHolders[index + 1]["thirdIdentificationType"]
              }
              onChange={(e) => handleInputChange(index + 1, e)}
              label="Type"
            >
              {getFilteredOptions(index + 1, "thirdIdentificationType").map(
                (consentType) => (
                  <MenuItem key={consentType} value={consentType}>
                    {consentType}
                  </MenuItem>
                )
              )}
            </Select> */}
            <Select
              value={shareholder.thirdIdentificationType}
              onChange={(e) => handleInputChange(index + 1, e)} // Adjust index
              label="Type"
              name="thirdIdentificationType"
              error={!!errors[index + 1]?.thirdIdentificationType}
              helperText={
                errors[index + 1]?.thirdIdentificationType
                  ? "This field is required"
                  : ""
              }
            >
              {getFilteredOptionsForOtherIndex(
                index + 1,
                "thirdIdentificationType"
              ).map((consentType) => (
                <MenuItem key={consentType} value={consentType}>
                  {consentType}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography
            variant="body1"
            sx={{
              fontSize: "1rem",
              fontWeight: "medium",
              color: "#2F2D3B",
              maxWidth: "745px",
              width: "100%",
            }}
          >
            Answer
          </Typography>
          <TextField
            label="Answer"
            name="thirdIdentificationAnswer"
            value={
              formData.shareHolders[index + 1]["thirdIdentificationAnswer"]
            }
            onChange={(e) => handleInputChange(index + 1, e)}
            error={!!errors[index + 1]?.thirdIdentificationAnswer}
            helperText={
              errors[index + 1]?.thirdIdentificationAnswer
                ? "This field is required"
                : ""
            }
            sx={{
              maxWidth: "555px",
              width: "100%",
              mb: "1.2rem",
              mt: "0.8rem",
            }}
          />
        </Box>
      ))}
      {formData.shareHolders.length < 4 && (
        <Typography
          variant="body1"
          sx={{
            fontSize: "1.1rem",
            fontWeight: "bold",
            color: "#2F2D3B",
            maxWidth: "745px",
            width: "100%",
            mt: 2,
            mb: 1,
          }}
        >
          Need to add other Directors or Shareholders?​
        </Typography>
      )}
      <Box
        sx={{
          display: "flex",
          maxWidth: "550px",
          width: "100%",
          mt: 2,
          justifyContent: "space-between",
        }}
      >
        {formData.shareHolders.length < 4 && (
          <Typography
            variant="body1"
            sx={{
              fontSize: "0.9rem",
              fontWeight: "medium",
              color: "#2069F7",
              cursor: "pointer",
            }}
            onClick={() => handleAddDirector()}
          >
            + Add Company Member
          </Typography>
        )}
        {formData.shareHolders.length > 1 && (
          <Typography
            variant="body1"
            sx={{
              fontSize: "0.9rem",
              fontWeight: "medium",
              color: "#2069F7",
              cursor: "pointer",
            }}
            onClick={() => handleRemoveDirector()}
          >
            - Remove Company Member
          </Typography>
        )}
      </Box>

      <Box mt={3} display="flex">
        <Button
          sx={{
            marginTop: "1rem",
            textTransform: "none",
            backgroundColor: "#ffffff",
            color: "#cfcfcf",
            border: "1px solid #cfcfcf",
            borderRadius: "1rem",
            padding: "0.4rem 1.5rem",
            fontWeight: 600,
            "&:hover": {
              color: "#2069F8",
              border: "1px solid #2069F8",
            },
          }}
          onClick={onBack}
        >
          Previous
        </Button>
        <Button
          sx={{
            marginTop: "1rem",
            marginLeft: "0.5rem",
            borderRadius: "1rem",
            backgroundColor: "#2069F8",
            color: "#ffffff",
            fontWeight: 700,
            padding: "0.5rem 1.5rem",
            textTransform: "none",
            width: "max-content",
            "&:hover": {
              backgroundColor: "#2069F8",
            },
          }}
          onClick={handleSubmit}
        >
          Continue
        </Button>
      </Box>
    </Box>
  );
};

export default Directors;
