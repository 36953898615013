import axios from "axios";
import { store } from "../store/Store";

const instance = axios.create({
  baseURL: "https://be-dev.therisegroup.co.uk",
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export const loginInstance = axios.create({
  baseURL: "https://be-dev.therisegroup.co.uk",
});

export default instance;
