import React from "react";

const Button = ({ value, isActive }) => {
  return (
    <div
      className="w-7 h-7 border-2 rounded-full flex items-center justify-center mr-2"
      style={{
        borderColor: isActive ? "#3056D3" : "#64748B",
        color: isActive ? "#3056D3" : "#64748B",
      }}
    >
      {value}
    </div>
  );
};

export default Button;
