import instance from "../instance";

export const DocumentsApi = async (documentsData) => {
  const data = { data: documentsData };
  try {
    const response = await instance.post(
      "/api/documents",
      JSON.stringify(data)
    );
    return response;
  } catch (error) {
    console.error("Error fetching customers:", error);
    return error;
  }
};

export const editDocumentsApi = async (id, documentsData) => {
  const data = { data: documentsData };
  try {
    const response = await instance.put(
      `/api/documents/${id}`,
      JSON.stringify(data)
    );
    return response;
  } catch (error) {
    console.error("Error fetching customers:", error);
    return error;
  }
};

export const getDocumentsApi = async (id) => {
  try {
    const response = await instance.get(
      `/api/documents?filters[userId][$eq]=${id}`
    );
    return response?.data?.data[0] || [];
  } catch (error) {
    console.error("Error fetching customers:", error);
    return error;
  }
};

export const BankingDetailsApi = async (bankingData) => {
  const data = { data: bankingData };
  try {
    const response = await instance.post("/api/bankings", JSON.stringify(data));
    return response;
  } catch (error) {
    console.error("Error fetching customers:", error);
    return error;
  }
};

export const editBankingDetailsApi = async (id, bankingData) => {
  const data = { data: bankingData };
  try {
    const response = await instance.put(
      `/api/bankings/${id}`,
      JSON.stringify(data)
    );
    return response;
  } catch (error) {
    console.error("Error fetching customers:", error);
    return error;
  }
};

export const getBankingDetailsApi = async (id) => {
  try {
    const response = await instance.get(`/api/bankings`);
    return response?.data?.data || [];
  } catch (error) {
    console.error("Error fetching customers:", error);
    return error;
  }
};

export const ExtrasApi = async (extrasData) => {
  const data = { data: extrasData };
  try {
    const response = await instance.post(
      "/api/company-details-extras",
      JSON.stringify(data)
    );
    return response;
  } catch (error) {
    console.error("Error fetching customers:", error);
    return error;
  }
};

export const editExtrasApi = async (id, extrasData) => {
  const data = { data: extrasData };
  try {
    const response = await instance.put(
      `/api/company-details-extras/${id}`,
      JSON.stringify(data)
    );
    return response;
  } catch (error) {
    console.error("Error fetching customers:", error);
    return error;
  }
};

export const getExtrasApi = async (id) => {
  try {
    const response = await instance.get(
      `/api/company-details-extras?filters[userId][$eq]=${id}`
    );
    return response?.data?.data[0] || [];
  } catch (error) {
    console.error("Error fetching customers:", error);
    return error;
  }
};

export const getSummaryData = async (id) => {
  try {
    const response = await instance.get(`/api/summary/${id}`);
    return response?.data || {};
  } catch (error) {
    console.error("Error fetching customers:", error);
    return error;
  }
};

export const getCompanyData = async (id) => {
  try {
    const response = await instance.get(`/api/users/${id}?populate=*`);
    return response?.data || {};
  } catch (error) {
    console.error("Error fetching customers:", error);
    return error;
  }
};
