import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const RedirectToSetup = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const companyName = queryParams.get("companyName");

    if (companyName) {
      navigate(`/setup?companyName=${companyName}`);
    } else {
      alert("Company name not fetched. Redirecting to the previous page.");
      window.location.href = "https://formations.therisegroup.co.uk/";
    }
  }, [location, navigate]);

  return null;
};

export default RedirectToSetup;
