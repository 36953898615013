import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./AuthSlice";
import profileSlice from "./ProfileSlice";
import contentSlice from "./ContentSlice";
import packageSlice from "./PackageSlice";

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    profile: profileSlice.reducer,
    content: contentSlice.reducer,
    package: packageSlice,
  },
});
