import instance, { loginInstance } from "../instance";

export const DirectorsApi = async (shareHolders) => {
  const data = { data: shareHolders };
  try {
    const response = await instance.post(
      "/api/directors",
      JSON.stringify(data)
    );
    return response;
  } catch (error) {
    console.error("Error fetching customers:", error);
    return error;
  }
};

export const editDirectorsApi = async (id, shareHolders) => {
  const data = { data: shareHolders };
  try {
    const response = await instance.put(
      `/api/directors/${id}`,
      JSON.stringify(data)
    );
    return response;
  } catch (error) {
    console.error("Error fetching customers:", error);
    return error;
  }
};

export const getDirectorsApi = async (id) => {
  try {
    const response = await instance.get(
      `/api/directors`
    );
    return response?.data?.data;
  } catch (error) {
    console.error("Error fetching customers:", error);
    return error;
  }
};

export const ShareTypesApi = async (roleTypes) => {
  const data = { data: roleTypes };
  try {
    const response = await instance.post(
      "/api/shares",
      JSON.stringify(data)
    );
    return response;
  } catch (error) {
    console.error("Error fetching customers:", error);
    return error;
  }
};

export const editShareTypesApi = async (id, roleTypes) => {
  const data = { data: roleTypes };
  try {
    const response = await instance.put(
      `/api/shares/${id}`,
      JSON.stringify(data)
    );
    return response;
  } catch (error) {
    console.error("Error fetching customers:", error);
    return error;
  }
};

export const getShareTypesApi = async (id) => {
  try {
    const response = await instance.get(
      `/api/shares`
    );
    return response?.data.data || [];
  } catch (error) {
    console.error("Error fetching customers:", error);
    return error;
  }
};

export const DetailsApi = async (personData) => {
  const data = { data: personData };
  try {
    const response = await instance.post(
      "/api/person-details",
      JSON.stringify(data)
    );
    return response;
  } catch (error) {
    console.error("Error fetching customers:", error);
    return error;
  }
};

export const editDetailsApi = async (id, personData) => {
  const data = { data: personData };
  try {
    const response = await instance.put(
      `/api/person-details/${id}`,
      JSON.stringify(data)
    );
    return response;
  } catch (error) {
    console.error("Error fetching customers:", error);
    return error;
  }
};

export const getDetailsApi = async (id) => {
  try {
    const response = await instance.get(
      `/api/person-details?filters[userId][$eq]=${id}`
    );
    return response?.data?.data[0] || [];
  } catch (error) {
    console.error("Error fetching customers:", error);
    return error;
  }
};

export const ShareHolderAddressApi = async (address) => {
  const data = { data: address };
  try {
    const response = await instance.post(
      "/api/share-holder-addresses",
      JSON.stringify(data)
    );
    return response;
  } catch (error) {
    console.error("Error fetching customers:", error);
    return error;
  }
};

export const editShareHolderAddressApi = async (id, address) => {
  const data = { data: address };
  try {
    const response = await instance.put(
      `/api/share-holder-addresses/${id}`,
      JSON.stringify(data)
    );
    return response;
  } catch (error) {
    console.error("Error fetching customers:", error);
    return error;
  }
};

export const getShareHolderAddressApi = async (id) => {
  try {
    const response = await instance.get(
      `/api/share-holder-addresses?filters[userId][$eq]=${id}`
    );
    return response?.data?.data[0] || [];
  } catch (error) {
    console.error("Error fetching customers:", error);
    return error;
  }
};

export const ShareHoldingApi = async (shareHolderAddress) => {
  const data = { data: shareHolderAddress };
  try {
    const response = await instance.post(
      "/api/shareholdings",
      JSON.stringify(data)
    );
    return response;
  } catch (error) {
    console.error("Error fetching customers:", error);
    return error;
  }
};

export const editShareHoldingApi = async (id, shareHolderAddress) => {
  const data = { data: shareHolderAddress };
  try {
    const response = await instance.put(
      `/api/shareholdings/${id}`,
      JSON.stringify(data)
    );
    return response;
  } catch (error) {
    console.error("Error fetching customers:", error);
    return error;
  }
};

export const getShareHoldingApi = async (id) => {
  try {
    const response = await instance.get(
      `/api/shareholdings`
    );
    return response?.data?.data || [];
  } catch (error) {
    console.error("Error fetching customers:", error);
    return error;
  }
};

export const SignificantControlApi = async (significantData) => {
  const data = { data: significantData };
  try {
    const response = await instance.post(
      "/api/share-holder-significant-controls",
      JSON.stringify(data)
    );
    return response;
  } catch (error) {
    console.error("Error fetching customers:", error);
    return error;
  }
};

export const editSignificantControlApi = async (id, significantData) => {
  const data = { data: significantData };
  try {
    const response = await instance.put(
      `/api/share-holder-significant-controls/${id}`,
      JSON.stringify(data)
    );
    return response;
  } catch (error) {
    console.error("Error fetching customers:", error);
    return error;
  }
};

export const getSignificantControlApi = async (id) => {
  try {
    const response = await instance.get(
      `/api/share-holder-significant-controls?filters[userId][$eq]=${id}`
    );
    return response?.data?.data[0] || [];
  } catch (error) {
    console.error("Error fetching customers:", error);
    return error;
  }
};

export const getNationalities = async () => {
  try {
    const response = await loginInstance.get(
      "https://api.worldbank.org/v2/country?format=json"
    );
    return response.data;
  } catch {}
};
