import React, { useState } from "react";
import { Box, TextField, Button } from "@mui/material";
import { styled } from "@mui/system";
import { checkCompanyNameAvailability } from "../../apiServices/services/CompanyHouseService";

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "20px",
    "& fieldset": {
      border: "none",
    },
  },
  "& .MuiInputBase-input": {
    padding: "0.5rem",
  },
  "& .MuiInputBase-input::placeholder": {
    fontSize: { md: "0.5rem", lg: "0.5rem", xl: "0.5rem" },
  },
  backgroundColor: "white",
  borderRadius: "20px",
}));

const SearchBar = ({ placeholder, handleSearchBar }) => {
  const [searchValue, setSearchValue] = useState("");

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleSearch = () => {
    handleSearchBar(searchValue);
  };

  return (
    <Box
      component="form"
      onSubmit={(e) => {
        e.preventDefault();
        handleSearch();
      }}
      display="flex"
      alignItems="center"
      sx={{
        backgroundColor: "white",
        borderRadius: "1rem",
        padding: "0.5rem",
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <CustomTextField
        variant="outlined"
        placeholder={placeholder}
        value={searchValue}
        onChange={handleInputChange}
        sx={{
          flexGrow: 1,
          borderRadius: "20px",
          "& .MuiOutlinedInput-root": {
            borderRadius: "20px",
          },
          "& .MuiInputBase-input::placeholder": {
            fontWeight: "500",
            fontSize: { md: "0.75rem", lg: "0.875rem", xl: "1.1rem" },
          },
        }}
      />
      <Button
        type="submit"
        variant="contained"
        sx={{
          marginTop: "1rem",
          marginLeft: "0.5rem",
          borderRadius: "1rem",
          backgroundColor: "#2069F8",
          color: "#ffffff",
          fontWeight: 700,
          padding: "0.5rem 1.5rem",
          textTransform: "none",
          width: "max-content",
          "&:hover": {
            backgroundColor: "#b4a4f5",
          },
        }}
        sx={{
          fontWeight: "700",
          padding: {
            lg: "0.5rem 1.25rem !important",
            xl: "0.75rem 1.5rem !important",
          },
          fontSize: {
            lg: "0.875rem",
            xl: "1rem",
          },
        }}
      >
        Search
      </Button>
    </Box>
  );
};

export default SearchBar;
