import instance, {loginInstance} from "../instance";

export const signupService = async (data) => {
  try {
    const response = await instance.post("/api/auth/local/register", data);
    return response;
  } catch (error) {
    console.error("Error fetching customers:", error);
    return error;
  }
};

export const loginService = async (data) => {
  try {
    const response = await loginInstance.post("/api/auth/local", data);
    return response;
  } catch (error) {
    console.error("Error fetching customers:", error);
    return error;
  }
};

export const sumbitDetails = async (data) => {
  try {
    const response = await instance.post("/api/company-details", data);
    return response;
  } catch (error) {
    console.error("Error fetching customers:", error);
    return error;
  }
};