import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";

const SuccessPage = ({ onBack }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/login");
  };

  const {
    successText = `Thank you! You have successfully submitted your details, we will get back to you soon!!`,
  } = location?.state || {};

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      sx={{
        minHeight: "100vh",
        backgroundColor: "#F6F5F8",
        color: "#2F2D3B",
      }}
    >
      <Header />
      <Box
        width={"100%"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        minHeight={"65vh"}
        backgroundColor={"#F9F9F9"}
      >
        <Box
          maxWidth={"1440px"}
          width={"71%"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          textAlign={"center"}
          sx={{
            padding: { md: "1rem 5rem" },
            position: "relative",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                textAlign: "center",
                mt: 8,
              }}
            >
              <Typography
                variant="h4"
                gutterBottom
                sx={{
                  fontWeight: "bold",
                }}
              >
                You’re all set to incorporate your business.
              </Typography>

              <Typography
                variant="h5"
                color="#2069F8"
                gutterBottom
                sx={{ mt: 4, fontWeight: "medium" }}
              >
                Thank you for creating an account. To finish your company
                incorporation, please provide some more information on our
                company incorporation platform.
              </Typography>

              <Button
                variant="contained"
                onClick={handleClick}
                sx={{
                  backgroundColor: "#2069F8",
                  borderRadius: "20px",
                  fontSize: "1.25rem",
                  fontWeight: "bold",
                  marginTop: "1.5rem",
                  textTransform: "none",
                  marginBottom: "0.25rem",
                  paddingX: "1.5rem",
                  ":hover": {
                    backgroundColor: "#181e54",
                  },
                }}
              >
                {"Login Now"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* <Footer /> */}
    </Box>
  );
};

export default SuccessPage;
