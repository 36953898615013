import React, { useState, useRef } from "react";
import { TextField, Button, Typography, Box, Container } from "@mui/material";
import { verifyOtp } from "../../apiServices/services/CheckoutServices";
import BackButton from "../../assets/logo/BackButton.svg";

const VerificationCodeForm = ({
  handlePrevious,
  handleClickSetEight,
  formData,
}) => {
  const [verificationCode, setVerificationCode] = useState(["", "", "", ""]);

  const inputRefs = useRef([]);

  const handleChange = (index, value) => {
    const newCode = [...verificationCode];
    newCode[index] = value;
    setVerificationCode(newCode);

    if (value && index < 3) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const otp = verificationCode.join("");
    const data = {
      mobileNumber: formData.phoneNumber,
      otp,
    };

    if (!otp) {
      alert("Please enter the Verification Code.");
      return;
    }

    verifyOtp(data).then((res) => {
      if (res.status === 200) {
        console.log(res);
        handleClickSetEight();
      }
    });
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: { xs: "0rem", md: "5rem" },
        maxWidth: { xs: "450px", md: "700px" },
        paddingTop: { xs: "1rem" },
      }}
    >
      <Typography
        variant="h5"
        sx={{ fontWeight: "bold", fontSize: { xs: "2rem", md: "2.3rem" } }}
      >
        Verify your Mobile Number
      </Typography>
      <Typography variant="h6" sx={{ mb: 4, mt: 2, color: "gray" }}>
        You will have just received an SMS with your RiseFormations Verification
        code. Please enter it below:
      </Typography>

      <Box
        sx={{
          display: "flex",
          gap: 2,
          mb: 4,
        }}
      >
        {verificationCode.map((value, index) => (
          <TextField
            key={index}
            inputRef={(el) => (inputRefs.current[index] = el)}
            inputProps={{
              maxLength: 1,
              style: { textAlign: "center", fontSize: "30px" },
            }}
            variant="outlined"
            value={value}
            onChange={(e) => handleChange(index, e.target.value)}
            sx={{ width: 80, height: 60 }}
          />
        ))}
      </Box>

      <Box sx={{ display: "flex", width: "100%", mt: 3 }}>
        <Button
          onClick={handlePrevious}
          sx={{
            color: "#000",
            fontSize: "1rem",
            textTransform: "none",
            marginRight: "1rem",
          }}
        >
          <img width={"70px"} src={BackButton} />
        </Button>
        <Button
          type="submit"
          sx={{
            color: "white",
            backgroundColor: "#2069F8",
            borderRadius: "20px",
            fontSize: "1.25rem",
            fontWeight: "bold",
            textTransform: "none",
            paddingX: "1.1rem",
            ":hover": {
              backgroundColor: "#181e54",
            },
          }}
        >
          Confirm
        </Button>
      </Box>
    </Box>
  );
};

export default VerificationCodeForm;
