import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import { useSelector } from 'react-redux'
// import AuthenticatedRouter from './AuthenticatedRouter'
import HomePage from "../pages/Homepage/HomePage";
import Layout from "../pages/Layout/Layout";
import Package from "../pages/Package/Package";
import SuccessPage from "../Components/SuccessPage/SuccessPage";
import DashboardLayout from "../pages/DashboardLayout/DashboardLayout";
import Dashboard from "../pages/InitialCompanySetup/Dashboard";
import DashboardIncorporation from "../pages/DashboardLayout/DashboardIncorporation";
import LoginPage from "../pages/login/Login";
import RedirectToSetup from "../pages/InitialCompanySetup/RedirectToSetup";
import AccountingPage from "../pages/AccountingPage/AccountingPage";
import AdvisoryPage from "../pages/AdvisoryPage/AdvisoryPage";
import LegalSupportPage from "../pages/LegalSupportPage/LegalSupportPage";
import SoftwarePage from "../pages/SoftwarePage/SoftwarePage";
import DashboardProgressChecklist from "../pages/Dashboard/Dashboard";

function Router() {
  // const token = useSelector((state) => state.auth.token);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<RedirectToSetup />} />
        <Route path="/setup" element={<Layout />}>
          <Route path="" element={<Dashboard />} />
        </Route>
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/login" element={<Layout />}>
        <Route path="" element={<LoginPage />} />
        </Route>
        <Route path="/dashboard" element={<DashboardLayout />}>
          <Route index element={<DashboardProgressChecklist />} />
        </Route>
        <Route path="/company" element={<DashboardLayout />}>
          <Route index element={<DashboardIncorporation />} />
          <Route path="accounting" element={<AccountingPage />} />
          <Route path="advisory" element={<AdvisoryPage />} />
          <Route path="legalSupport" element={<LegalSupportPage />} />
          <Route path="software" element={<SoftwarePage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
