import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  LinearProgress,
  Collapse,
  Button,
  Grid,
} from "@mui/material";
import Matt from "../../assets/Matt.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import File from "../../assets/logo/File.svg";
import Passbook from "../../assets/logo/Passbook.svg";
import Insurance from "../../assets/logo/Insurance.svg";
import Bars from "../../assets/logo/Bars.svg";
import OnlineMeeting from "../../assets/logo/OnlineMeeting.svg";
import Quickbooks from "../../assets/logo/Quickbooks.svg";
import Xero from "../../assets/logo/Xero.svg";
import BarclaysLogo from "../../assets/logo/BarclaysLogo.svg";
import KBLogo from "../../assets/logo/KBLogo.svg";
import MonzoLogo from "../../assets/logo/MonzoLogo.svg";
import RevolutLogo from "../../assets/logo/Revolut.svg";
import { updateFormStatus } from "../../apiServices/services/ProfileServices";
import { getCompanyData } from "../../apiServices/services/DocumentsServices";
import Logo from "../../assets/logo/LogoRiseGroupBlack.svg";
import { useNavigate } from "react-router-dom";

const DashboardProgressChecklist = () => {
  const steps = [
    {
      label: "Incorporate your company",
      completed: false,
      form: "isCompanyIncorporated",
    },
    {
      label: "Set up your Business Bank Account",
      completed: false,
      form: "isBussinesBankSetup",
    },
    {
      label: "Be compliant with Business Insurance",
      completed: false,
      form: "isBussinessInsuranceCompliant",
    },
    {
      label: "Get your finances in order",
      completed: false,
      form: "isFinanceDone",
    },
    {
      label: "Book in a free Business Consultation",
      completed: false,
      form: "isBusinessConsultationDone",
    },
  ];

  const [openTab, setOpenTab] = useState(null);
  const [stepState, setStepState] = useState(steps);
  const navigate = useNavigate();

  const progress =
    (stepState.filter((step) => step.completed).length / stepState.length) *
    100;

  const handleToggle = (index) => {
    setOpenTab(openTab === index ? null : index);
  };

  useEffect(() => {
    getCompanyData(localStorage.getItem("userId")).then((response) => {
      if (response) {
        const updatedSteps = stepState.map((step) => ({
          ...step,
          completed: response[step.form] || false,
        }));

        setStepState(updatedSteps);
      }
    });
  }, []);

  const handleChangeStatus = async (index, step) => {
    const updatedSteps = stepState.map((item, idx) =>
      idx === index ? { ...item, completed: !item.completed } : item
    );
    const formName = stepState[index].form;

    try {
      const response = await updateFormStatus(localStorage.getItem("userId"), {
        [formName]: true,
      });

      if (response.status === 200) {
        // console.log("updatedSteps", updatedSteps);
        setStepState(updatedSteps);
      } else {
        console.error("Failed to update form status:", response.status);
      }
    } catch (error) {
      console.error("Error updating form status:", error);
    }
  };

  return (
    <Box p={4} m={3} mt={0.5} style={{ backgroundColor: "#f5f7fb" }}>
      <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
        Hello, {localStorage.getItem("username")} 👋
      </Typography>
      <Box
        sx={{
          backgroundColor: "white",
          width: "100%",
          maxWidth: "1200px",
          padding: "3rem",
          paddingTop: "1rem",
          paddingBottom: "1rem",
          mt: "2rem",
          minHeight: "auto", // Allow content to grow
          borderRadius: "10px",
        }}
      >
        <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
          You’ve incorporated – now set your business up for success
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ color: "gray" }}>
          Get your business off on the right foot by completing the following
          steps.
        </Typography>

        <Box mt={2}>
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{
              height: "1rem",
              borderRadius: "2rem",
              backgroundColor: "#E6EBF4",
              "& .MuiLinearProgress-bar": {
                backgroundColor: "#0E9F64", // Set the progress bar color to green
              },
            }}
          />
          <Typography
            variant="body2"
            color="textSecondary"
            align="right"
            mt={1}
          >
            {Math.round(progress)}%
          </Typography>
        </Box>

        <List>
          {stepState.map((step, index) => (
            <React.Fragment key={index}>
              <ListItem>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      fontWeight: "bold",
                      height: "5vh",
                      marginBottom: "0.5rem",
                    }}
                    disableGutters
                  >
                    <div className="bg-[#E6EBF4] w-12 h-12 flex items-center justify-center mr-6">
                      <img
                        src={
                          step.label.trim() === "Incorporate your company"
                            ? File
                            : step.label.trim() ===
                              "Set up your Business Bank Account"
                            ? Passbook
                            : step.label.trim() ===
                              "Be compliant with Business Insurance"
                            ? Insurance
                            : step.label.trim() === "Get your finances in order"
                            ? Bars
                            : OnlineMeeting
                        }
                        alt="notavailable"
                      />
                    </div>

                    <ListItemText
                      primary={step.label}
                      primaryTypographyProps={{
                        sx: {
                          fontWeight: "medium",
                          fontSize: "1.1rem",
                          cursor: "pointer",
                        },
                      }}
                      onClick={() => handleToggle(index)}
                    />

                    {step.completed ? (
                      <CheckCircleIcon
                        sx={{ fontSize: 34, color: "blue" }}
                        color="primary"
                      />
                    ) : (
                      <RadioButtonUncheckedIcon
                        sx={{ fontSize: 34, color: "blue", cursor: "pointer" }}
                        color="action"
                        onClick={() => handleChangeStatus(index, step)}
                      />
                    )}
                  </Box>
                  {openTab === index && openTab === 1 && (
                    <Box
                      sx={{
                        borderRadius: "5px",
                        marginBottom: "1rem",
                        marginLeft: "4.5rem",
                        width: "100%",
                        maxWidth: "900px",
                      }}
                    >
                      <Typography>
                        A business bank account separates personal and business
                        finances, simplifies accounting and tax filing, and
                        helps build professionalism and access to financial
                        services.
                        <br />​ <br />
                        Choose from any one of our Banking Partners to benefit
                        from their business banking services:
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "1rem", // Adjust gap between buttons
                          mt: 3,
                        }}
                      >
                        {/* Monzo Button */}
                        <Button
                          sx={{
                            backgroundColor: "#FF4F40", // Monzo Red
                            color: "white",
                            borderRadius: "10px",
                            paddingX: "2rem",
                            paddingY: "0.5rem",
                            textTransform: "none", // To keep button text as-is
                            display: "flex",
                            alignItems: "center",
                            fontWeight: "bold",
                            justifyContent: "center",
                            "&:hover": {
                              backgroundColor: "#e63c3c", // Slightly darker on hover
                            },
                          }}
                          onClick={() => {
                            window.open(
                              "https://business.monzo.com/join/a/rzsj85p",
                              "_blank"
                            );
                          }}
                          startIcon={
                            <img
                              src={MonzoLogo}
                              alt="Monzo"
                              style={{ width: "60px", height: "35px" }}
                            />
                          }
                        >
                          Monzo
                        </Button>

                        {/* Barclays Button */}
                        <Button
                          sx={{
                            backgroundColor: "#00AEEF", // Barclays Blue
                            color: "white",
                            borderRadius: "10px",
                            fontWeight: "bold",
                            paddingX: "2rem",
                            paddingY: "0.5rem",
                            textTransform: "none",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            "&:hover": {
                              backgroundColor: "#0089c7",
                            },
                          }}
                          // onClick={() => {
                          //   window.open(
                          //     "https://business.monzo.com/join/a/rzsj85p",
                          //     "_blank"
                          //   );
                          // }}
                          startIcon={
                            <img
                              src={BarclaysLogo}
                              alt="Barclays"
                              style={{ width: "60px", height: "35px" }}
                            />
                          }
                        >
                          Barclays
                        </Button>

                        {/* Revolut Button */}
                        <Button
                          sx={{
                            backgroundColor: "#000000", // Revolut Black
                            fontWeight: "bold",
                            color: "white",
                            borderRadius: "10px",
                            paddingY: "0.5rem",
                            paddingX: "2rem",
                            textTransform: "none",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            "&:hover": {
                              backgroundColor: "#333333",
                            },
                          }}
                          onClick={() => {
                            window.open(
                              "https://revolutbusiness.ngih.net/9gvG7j",
                              "_blank"
                            );
                          }}
                          startIcon={
                            <img
                              src={RevolutLogo}
                              alt="Revolut"
                              style={{ width: "60px", height: "35px" }}
                            />
                          }
                        >
                          Revolut
                        </Button>
                      </Box>
                    </Box>
                  )}
                  {openTab === index && openTab === 2 && (
                    <Box
                      sx={{
                        borderRadius: "5px",
                        marginBottom: "1rem",
                        marginLeft: "4.5rem",
                        width: "100%",
                        maxWidth: "900px",
                      }}
                    >
                      <Typography>
                        Business insurance is essential because it protects your
                        company from financial losses due to unforeseen events,
                        accidents, mistakes or lawsuits. It also enhances
                        credibility with customers and partners, while ensuring
                        compliance with legal and contractual requirements.
                        <br />​ <br />​ Click on the tab below to set up all of
                        your Business Insurance requirements: ​
                      </Typography>
                      <Box
                        sx={{
                          borderRadius: "5px",
                          marginBottom: "1rem",
                          marginTop: "1.2rem",
                          width: "100%",
                          maxWidth: "900px",
                          height: "18vh",
                          backgroundColor: "#0D333F",
                          display: "flex",
                          paddingX: "4rem",
                          alignItems: "center",
                        }}
                      >
                        <Grid item xs={12} sm={6} sx={{ marginRight: "2rem" }}>
                          <Box
                            display="flex"
                            alignItems="center"
                            onClick={() => {
                              window.open(
                                "https://risegroup.kingsbridge.co.uk/",
                                "_blank"
                              );
                            }}
                          >
                            <img
                              src={KBLogo}
                              alt="KingsBridge"
                              style={{
                                width: "400px",
                                height: "300px",
                                cursor: "pointer",
                              }}
                            />
                          </Box>
                        </Grid>

                        <Box>
                          <Typography
                            variant="body1"
                            sx={{
                              color: "#ffffff",
                              fontWeight: "medium",
                              marginBottom: "0.5rem",
                            }}
                          >
                            • Professional Indemnity insurance
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{
                              color: "#ffffff",
                              fontWeight: "medium",
                              marginBottom: "0.5rem",
                            }}
                          >
                            • Public Liability insurance
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{
                              color: "#ffffff",
                              fontWeight: "medium",
                              marginBottom: "0.5rem",
                            }}
                          >
                            • Employers' Liability insurance <br />
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{
                              color: "#ffffff",
                              fontWeight: "medium",
                              marginBottom: "0.5rem",
                            }}
                          >
                            • Tax Liability insurance
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  )}
                  {openTab === index && openTab === 3 && (
                    <Box
                      sx={{
                        borderRadius: "5px",
                        marginBottom: "1rem",
                        marginLeft: "4.5rem",
                        width: "100%",
                        maxWidth: "900px",
                      }}
                    >
                      <Typography>
                        Staying on top of your business finances is vital for
                        informed decision-making, effective cash flow
                        management, and being able to identify any financial
                        issues. It also helps with budgeting, tax compliance,
                        and strategic planning for growth. ​
                        <br />​ <br />​ ​ Access the best discounts for the UK’s
                        leading Accounting Software packages:
                      </Typography>
                      <Box
                        sx={{
                          borderRadius: "5px",
                          height: "10vh",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "start",
                            marginTop: "1rem",
                            borderRadius: "0.5rem",
                            backgroundColor: "#0D333F",
                            color: "#ffffff",
                            fontWeight: 700,
                            padding: "0.5rem 1.5rem",
                            textTransform: "none",
                            width: "14vw",
                            height: "8vh",
                          }}
                          onClick={() => {
                            window.open(
                              "https://quickbooks.partnerlinks.io/qb-discounted-pricing-plans",
                              "_blank"
                            );
                          }}
                        >
                          <img
                            src={Quickbooks}
                            alt="Quickbooks"
                            className="mr-2"
                          />
                          Quickbooks
                        </Box>
                        <Box
                          sx={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "start",
                            marginTop: "1rem",
                            borderRadius: "0.5rem",
                            backgroundColor: "#0378C8",
                            color: "#ffffff",
                            fontWeight: 700,
                            padding: "0.5rem 1.5rem",
                            textTransform: "none",
                            width: "14vw",
                            height: "8vh",
                            marginLeft: "2rem",
                          }}
                          onClick={() => {
                            window.open(
                              "https://xero5440.partnerlinks.io/xero-discounted-pricing-plans",
                              "_blank"
                            );
                          }}
                        >
                          <img src={Xero} alt="Quickbooks" className="mr-2" />
                          Xero
                        </Box>
                      </Box>
                    </Box>
                  )}
                  {openTab === index && openTab === 4 && (
                    <Box
                      sx={{
                        borderRadius: "5px",
                        marginBottom: "1rem",
                        marginLeft: "4.5rem",
                        width: "100%",
                        maxWidth: "900px",
                      }}
                    >
                      <Typography>
                        Staying on top of your business finances is vital for
                        informed decision-making, effective cash flow
                        management, and being able to identify any financial
                        issues. It also helps with budgeting, tax compliance,
                        and strategic planning for growth.
                        <br />​ <br />
                        ​Access the best discounts for the UK’s leading
                        Accounting Software packages: ​
                      </Typography>
                      <Box
                        sx={{
                          borderRadius: "5px",
                          marginBottom: "1rem",
                          marginTop: "1.2rem",
                          width: "100%",
                          maxWidth: "900px",
                          height: "18vh",
                          backgroundColor: "#E6EBF4",
                          display: "flex",
                          paddingX: "2rem",
                          alignItems: "center",
                        }}
                      >
                        <img src={Matt} className="h-[140px] w-[130px]" />
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "2rem",
                          }}
                        >
                          <Typography
                            sx={{
                              width: "100%",
                              maxWidth: "300px",
                              fontWeight: "medium",
                            }}
                          >
                            Book a free 30-minute consultation with ​ Matt,
                            Co-Founder at Rise Group​
                          </Typography>
                          <Button
                            sx={{
                              marginTop: "1rem",
                              borderRadius: "0.5rem",
                              backgroundColor: "#2069F8",
                              color: "#ffffff",
                              fontWeight: 700,
                              padding: "0.5rem 1.5rem",
                              textTransform: "none",
                              width: "max-content",
                              "&:hover": {
                                backgroundColor: "blue",
                              },
                            }}
                            onClick={() => {
                              window.open(
                                "https://meetings.hubspot.com/matt2751/ascend-law",
                                "_blank"
                              );
                            }}
                          >
                            Book a call
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  )}

                  <div className="w-full h-[1px] bg-gray-200 mb-2 mt-2"></div>
                </Box>
              </ListItem>
            </React.Fragment>
          ))}
        </List>
      </Box>

      <Box
        mt={2}
        p={2}
        borderRadius={4}
        sx={{
          width: "100%",
          maxWidth: "1200px",
        }}
      >
        <Typography sx={{ fontSize: "14px" }}>
          Please note, Rise Group Services is a consulting group registered in
          the England. We provide our own services through Jump Accounting
          Limited, August Law Limited and 55 Technologies UK limited.
          <br />
          <br /> ​ ​In some cases, Rise Group Services provides recommendations
          of software's and partners to potentially support you as a UK
          business. Should you choose to use any of the recommended services,
          you accept that Rise Group Services Limited does not accept any
          liability in the event of loss or damages.
          <br />
          <br /> ​​Rise Group Services in some cases may receive compensation
          for introducing you to different partners, which in turn pays for the
          upkeep of the Rise Group platform. ​
        </Typography>
      </Box>
    </Box>
  );
};

export default DashboardProgressChecklist;
