
import { createSlice } from "@reduxjs/toolkit";
const profileSlice = createSlice({
  name: "profile",
  initialState: {
   user:'',
  },
  reducers: {
    setUserHandler(state, action) {
      state.user = action.payload;
    },
  },
});

export const profileActions = profileSlice.actions;

export default profileSlice;