import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Box,
  Button,
  Link,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useSelector } from "react-redux";
import {
  getSummaryData,
  getCompanyData,
} from "../../apiServices/services/DocumentsServices";
import { updateFormStatus } from "../../apiServices/services/ProfileServices";

const Summary = ({ onContinue, onBack }) => {
  const packageState = useSelector((state) => state.package?.data);
  const [checked, setChecked] = useState({
    confirmCompanyFormation: false,
    confirmLawfulActivities: false,
    acceptTerms: false,
    authorityForBankDetails: false,
  });

  const [companyDetailsData, setCompanyDetailsData] = useState({});
  const [registeredOfficeData, setRegisteredOfficeData] = useState({});
  const [typeOfShares, setTypeOfShares] = useState([]);
  const [directorsData, setDirectorsData] = useState([]);
  const [shareHoldingsData, setShareHoldingsData] = useState([]);
  const [documentsData, setDocumentsData] = useState({});
  const [bankingData, setBankingData] = useState({});
  const [companyData, setCompanyData] = useState("");
  const userId = localStorage.getItem("userId");
  const options = { day: "2-digit", month: "short", year: "numeric" };

  const handleChange = (event) => {
    setChecked({
      ...checked,
      [event.target.name]: event.target.checked,
    });
  };

  const handleSubmit = (event) => {
    const userId = localStorage.getItem("userId");
    updateFormStatus(userId, {
      isFormCompleted: true,
    }).then((res) => {
      if (res.status === 200) {
        onContinue();
        localStorage.setItem("activeForm", "isFormCompleted");
        window.dispatchEvent(new Event("storage"));
      } else {
      }
    });
    onContinue();
  };

  function getActiveRoles(roles) {
    const activeRoles = [];
    for (const role in roles) {
      if (roles[role]) {
        activeRoles.push(`${role}, `);
      }
    }
    return activeRoles;
  }

  useEffect(() => {
    getCompanyData(userId).then((response) => {
      if (response) {
        setCompanyData(response?.company?.companyName);
        // console.log(response);
        setCompanyDetailsData(response?.companyDetails);
        setDirectorsData(response.directors?.shareHolders);
        setBankingData(response?.banking);
        // setDocumentsData(response.documents);
        setTypeOfShares(response.shares?.shares);
        setShareHoldingsData(response?.shareholdings?.shareHolders);
        // setRegisteredOfficeData(response.address);
      }
    });
    // getCompanyData(userId).then((response) => {
    //   if (response) {
    //     setCompanyData(response?.company?.companyName);
    //   }
    // });
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        p: 4,
        borderRadius: 2,
        boxShadow: 3,
        bgcolor: "background.paper",
        margin: "0 auto",
        paddingLeft: "3rem",
      }}
    >
      <Typography
        sx={{
          color: "1C2434",
          fontWeight: "bold",
          fontSize: "1.4rem",
          marginBottom: "1rem",
        }}
      >
        Summary
      </Typography>
      {/* <div className="w-full h-[2px] bg-gray-100 mb-8 mt-2"></div> */}
      <Typography
        sx={{
          fontWeight: "500",
          fontSize: "1.04rem",
          width: "100%",
          maxWidth: "800px",
        }}
        paragraph
      >
        Please check all your details carefully and make any necessary changes
        by selecting the pencil icon for the appropriate section. You can print
        a pdf of this summary <Link href="#">here</Link>.
      </Typography>

      <div className=" w-[900px] h-[1px] bg-black mb-8 mt-8"></div>

      <Box mt={3}>
        <Typography sx={{ fontWeight: "600", fontSize: "1.1rem" }} mb={2}>
          Companies Details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "500" }}>Company name</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "500" }}>
              {companyDetailsData?.companyName} {companyDetailsData.suffix}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "500" }}>
              Registered address
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "500" }}>
              {companyDetailsData?.manualAddress === "no" &&
                "Use Central London Regsitered Address"}
              {companyDetailsData?.manualAddress === "yes" && (
                <Typography sx={{ fontWeight: "500" }}>
                  {companyDetailsData?.officeAddress}
                  {","}
                  <br />
                  {companyDetailsData?.addressLine1} {", "}
                  {companyDetailsData?.addressLine2}
                  <br />
                  {companyDetailsData?.town}
                  <br />
                  {companyDetailsData?.country} {", "}
                  {companyDetailsData?.postcode}
                  <br />
                </Typography>
              )}{" "}
            </Typography>
          </Grid>
          {/* <Grid item xs={6}>
            <Typography sx={{ fontWeight: "500" }}>
              Registered email address
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {registeredOfficeData?.useRegisteredAddress &&
              "Use London registered address"}
            {registeredOfficeData?.registrationEmail}
          </Grid> */}
          {/* <Grid item xs={6}>
            <Typography sx={{ fontWeight: "500" }}>
              The registered office address is an appropriate address outlines
              in section 86(2) of the Companies Act 2006
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "500" }}>
              {registeredOfficeData?.gdprConsent ? "Confirmed" : ""}
            </Typography>
          </Grid> */}
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "500" }}>
              Registered email address
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "500" }}>
              {companyDetailsData?.email}
            </Typography>
          </Grid>
          {/* <Grid item xs={6}>
            <Typography sx={{ fontWeight: "500" }}>
              The registered email address is an appropriate email address as
              outlined in section 88A(2) of the Companies Act 2006
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "500" }}>
              {registeredOfficeData?.termsConsent ? "Confirmed" : ""}
            </Typography>
          </Grid> */}
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "500" }}>SIC Codes</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "500" }}>
              {companyDetailsData?.additionalTrade?.map((value, index) => (
                <React.Fragment key={index}>
                  {value}
                  <br />
                </React.Fragment>
              ))}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      {/* <div className=" w-[900px] h-[1px] bg-black mb-8 mt-8"></div> */}

      {/* <Box mt={4}>
        <Typography sx={{ fontWeight: "600" }} variant="h6" gutterBottom>
          Share class details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "500" }}>
              {typeOfShares?.shareClass ?? "Ordinary"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "500" }}></Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "600" }}>Currency</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "500" }}>
              {typeOfShares?.currency ?? "GBP"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "600" }}>
              Nominal value per share
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "500" }}>
              £ {typeOfShares?.nominalValue ?? "1"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "600" }}>Type of share</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "500" }}>
              {typeOfShares?.typeOfShare ?? "Ordinary"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "600" }}>
              Prescribed particulars
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "500" }}>
              {typeOfShares?.particulars ?? ""}
            </Typography>
          </Grid>
        </Grid>
      </Box> */}

      <div className=" w-[900px] h-[1px] bg-black mb-8 mt-8"></div>

      <Box mt={4}>
        {directorsData?.map((shareHolder, index) => (
          <>
            <Typography
              sx={{
                fontWeight: "700",
                fontSize: "1rem",
                marginBottom: "1rem",
                marginTop: "2rem",
              }}
              gutterBottom
            >
              Director {index + 1}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontWeight: "500",
                  }}
                >
                  Name
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontWeight: "500",
                  }}
                >
                  {shareHolder.firstName}{" "}
                  {shareHolder.middlename ? shareHolder.middlename : ""}{" "}
                  {shareHolder.surname ? shareHolder.surname : ""}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "500" }}>
                  Email Address
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "500" }}>
                  {shareHolder.email}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "500" }}>Phone Number</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "500" }}>
                  {shareHolder?.mobileNumber}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "500" }}>Home Address</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "500" }}>
                  {shareHolder?.addressLine1} , {shareHolder?.addressLine2}
                  <br />
                  {shareHolder?.country} <br /> {shareHolder?.postCode}
                  <br />
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "500" }}>
                  Date of birth
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "500" }}>
                  {new Date(shareHolder.dateOfBirth)
                    .toLocaleDateString("en-GB", options)
                    .replace(",", "")}
                </Typography>
              </Grid>
            </Grid>
            <Typography
              sx={{
                fontWeight: "700",
                fontSize: "1rem",
                marginBottom: "1rem",
                marginTop: "1.5rem",
              }}
            >
              Companies House Identification Items
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontWeight: "500",
                  }}
                >
                  {shareHolder.firstIdentificationType}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "500" }}>
                  {shareHolder.firstIdentificationAnswer}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "500" }}>
                  {shareHolder.seconddentificationType}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "500" }}>
                  {shareHolder.secondIdentificationAnswer}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "500" }}>
                  {shareHolder.thirdIdentificationType}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "500" }}>
                  {shareHolder.thirdIdentificationAnswer}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "500" }}>Position</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "500" }}>
                  {shareHolder.position}
                </Typography>
              </Grid>
            </Grid>
          </>
        ))}
      </Box>

      <div className=" w-[900px] h-[1px] bg-black mb-8 mt-8"></div>

      <Box mt={4}>
        <Box>
          <Typography
            sx={{ fontWeight: "600", fontSize: "1.1rem", marginBottom: "1rem" }}
            gutterBottom
          >
            Type Of Shares
          </Typography>
          <Grid container spacing={2}>
            {typeOfShares?.map((shares, index) => (
              <>
                <Grid item xs={6}>
                  <Typography sx={{ fontWeight: "500" }}>
                    Share Class {index + 1}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontWeight: "500" }}>{shares}</Typography>
                </Grid>
              </>
            ))}
          </Grid>
        </Box>
      </Box>

      <div className=" w-[900px] h-[1px] bg-black mb-8 mt-8"></div>

      <Box mt={4}>
        <Typography
          sx={{ fontWeight: "600", fontSize: "1.1rem", marginBottom: "1rem" }}
          gutterBottom
        >
          Shareholding
        </Typography>
        {shareHoldingsData?.map((shareHolder, index) => (
          <>
            <Typography
              sx={{
                fontWeight: "700",
                fontSize: "1rem",
                marginBottom: "1rem",
                marginTop: "1.5rem",
              }}
              gutterBottom
            >
              Director {index + 1}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "500" }}>Name</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontWeight: "500",
                  }}
                >
                  {shareHolder.name}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "500" }}>Share Class</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "500" }}>
                  {shareHolder.typeOfShare}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "500" }}>
                  No. of Shares
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "500" }}>
                  {shareHolder.noOfShares}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "500" }}>
                  Person of Significant Control
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "500" }}>
                  {shareHoldingsData.length === 1 ? "Yes" : "No"}
                </Typography>
              </Grid>
            </Grid>
          </>
        ))}
      </Box>

      {/* <Box mt={4}>
        <Typography variant="h6" gutterBottom>
          Documents and extras
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "600" }}>
              Articles of association
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "500" }}></Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "600" }}>
              Model articles of association
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "500" }}>
              {documentsData?.articleOfAssociation
                ? "Yes, No extra cost"
                : "No"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "600" }}>
              First board minute - electronic
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "500" }}>
              {documentsData?.articleOfAssociation
                ? "Yes - Included in package"
                : "No"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "600" }}>
              Share certificate - Online basic share certificates
            </Typography>
          </Grid> 
           <Grid item xs={6}>
            <Typography sx={{ fontWeight: "500" }}>
              {documentsData?.electronicCertificate !== null
                ? "Yes - No extra cost"
                : "No"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "600" }}>
              Printed Incorporation Documents
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "500" }}>
              {documentsData?.printedDocument ? "Yes" : "No"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "600" }}>
              Bound company records
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "500" }}>
              {formData?.documents.boundCompanyResgister ? "Yes" : "No"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "600" }}>
              Letter notifying HMRC the company is dormant
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "500" }}>
              {formData?.documents.notifyingLetter ? "Yes" : "No"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "600" }}>
              Officer consent to act letter(s)
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "500" }}>
              {formData?.documents.officerConsent ? "Yes" : "No"}
            </Typography>
          </Grid>
        </Grid>
      </Box> */}

      <div className=" w-[900px] h-[1px] bg-black mb-8 mt-8"></div>

      <Box mt={4}>
        <Box>
          <Typography
            sx={{ fontWeight: "600", fontSize: "1.1rem", marginBottom: "1rem" }}
            gutterBottom
          >
            Banking
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography sx={{ fontWeight: "500" }}>
                Banking Provider
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontWeight: "500" }}>
                {bankingData?.isisBarclays && "Barclays"}{" "}
                {bankingData?.isMonzo && "Monzo"}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <div className=" w-[900px] h-[1px] bg-black mb-8 mt-8"></div>

        {/* <Box mt={4}>
          <Typography variant="h6" gutterBottom>
            Extras
          </Typography>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={6}>
              <Typography sx={{ fontWeight: "600" }}>
                Same day service
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontWeight: "500" }}>
                {formData?.extras.sameDayService
                  ? "Yes - Additional Companies House fee applies"
                  : "No"}
              </Typography>
            </Grid>
            <Grid item xs={6} mt={1}>
              <Typography sx={{ fontWeight: "600" }}>
                Certificate of good standing
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontWeight: "500" }}>
                {formData?.extras.certificate ? "Yes" : "No"}
              </Typography>
            </Grid>
            <Grid item xs={6} mt={1}>
              <Typography sx={{ fontWeight: "600" }}>Company seal</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontWeight: "500" }}>
                {formData?.extras.companySeal ? "Yes" : "No"}
              </Typography>
            </Grid>
            <Grid item xs={6} mt={1}>
              <Typography sx={{ fontWeight: "600" }}>Company stamp</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontWeight: "500" }}>
                {formData?.extras.companyStamp ? "Yes" : "No"}
              </Typography>
            </Grid>
            <Grid item xs={6} mt={1}>
              <Typography sx={{ fontWeight: "600" }}>
                Company name plate
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontWeight: "500" }}>
                {formData?.extras.companyNamePlate ? "Yes" : "No"}
              </Typography>
            </Grid>
            <Grid item xs={6} mt={1}>
              <Typography sx={{ fontWeight: "600" }}>Domain name</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontWeight: "500" }}>
                {formData?.extras.domainName ? "Yes - No extra cost" : "No"}
              </Typography>
            </Grid>
          </Grid>
        </Box> */}
      </Box>

      {/* <div className="w-full h-[2px] bg-gray-100 mb-8 mt-10"></div> */}

      {/* <Box component="form" sx={{ mt: 2 }}>
        <Typography variant="h6" sx={{ mb: 4 }}>
          Confirmation
        </Typography>

        <FormControlLabel
          control={
            <Checkbox
              checked={checked.confirmCompanyFormation}
              onChange={handleChange}
              name="confirmCompanyFormation"
              color="primary"
            />
          }
          label={
            <Typography sx={{ fontWeight: "600" }}>
              Tick the box to confirm that each subscriber to this memorandum of
              association wishes to form a company under the Companies Act 2006
              and agrees to become a member of the company and to take at least
              one share.
            </Typography>
          }
        />

        <FormControlLabel
          sx={{ mt: 2 }}
          control={
            <Checkbox
              checked={checked.confirmLawfulActivities}
              onChange={handleChange}
              name="confirmLawfulActivities"
              color="primary"
            />
          }
          label={
            <Typography sx={{ fontWeight: "600" }}>
              Tick the box to confirm that the intended future activities of the
              company are lawful.
            </Typography>
          }
        />

        <FormControlLabel
          sx={{ mt: 2 }}
          control={
            <Checkbox
              checked={checked.acceptTerms}
              onChange={handleChange}
              name="acceptTerms"
              color="primary"
            />
          }
          label={
            <Typography sx={{ fontWeight: "600" }}>
              I confirm my acceptance of the Rise Formations terms and
              conditions and system capabilities.
            </Typography>
          }
        />

        {bankingData?.barclaysBank && (
          <FormControlLabel
            sx={{ mt: 2 }}
            control={
              <Checkbox
                checked={checked.authorityForBankDetails}
                onChange={handleChange}
                name="authorityForBankDetails"
                color="primary"
              />
            }
            label={
              <Typography sx={{ fontWeight: "600" }}>
                Tick the box to confirm that you have the explicit authority
                from the officers of, and those who benefit from, the company
                being formed for their details and those of the company to be
                passed to Monzo Business Bank for the purpose of opening and
                providing the bank account, and to being contacted directly by
                Monzo Business Bank to open the bank account.
              </Typography>
            }
          />
        )}

        <Typography sx={{ mt: 3, fontWeight: "600" }}>
          There will also be an opportunity to print this confirmation once your
          application has been submitted.
        </Typography>
      </Box> */}

      <Box mt={4} display="flex" className="px-3">
        <Button
          sx={{
            marginTop: "1rem",
            textTransform: "none",
            backgroundColor: "#ffffff",
            color: "#cfcfcf",
            border: "1px solid #cfcfcf",
            borderRadius: "1rem",
            padding: "0.4rem 1.5rem",
            fontWeight: 600,
            "&:hover": {
              color: "#2069F8",
              border: "1px solid #2069F8",
            },
          }}
          onClick={onBack}
        >
          Previous
        </Button>
        <Button
          sx={{
            marginTop: "1rem",
            marginLeft: "0.5rem",
            borderRadius: "1rem",
            backgroundColor: "#2069F8",
            color: "#ffffff",
            fontWeight: 700,
            padding: "0.5rem 1.5rem",
            textTransform: "none",
            width: "max-content",
            "&:hover": {
              backgroundColor: "#2069F8",
            },
          }}
          onClick={() => handleSubmit()}
        >
          Continue
        </Button>
      </Box>
    </Box>
  );
};

export default Summary;
