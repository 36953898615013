
import { createSlice } from "@reduxjs/toolkit";
const contentSlice = createSlice({
    name: "content",
    initialState: {
        allContent:'',
        content:'',
    },
    reducers: {
        setContent(state, action) {
            state.content = action.payload;
        },
        setAllContent(state, action) {
            state.allContent = action.payload;
        },
    },
});

export const contentActions = contentSlice.actions;

export default contentSlice;