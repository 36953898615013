import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {
    companyName: "",
    billingDetails: {
      title: "",
      firstName: "",
      lastName: "",
      phone: "",
      billingAddress: {
        postcode: "",
        name_number: "",
        street: "",
        town: "",
        country: "",
      },
    },
    cart: [
      {
        packageName: "",
        price: null,
      },
    ],
    signup: {
      username: "",
      email: "",
      password: "",
    },
  },
};

const packageSlice = createSlice({
  name: "package",
  initialState,
  reducers: {
    setPackage(state, action) {
      state.data.cart[0] = action.payload;
    },
    setBillingDetails(state, action) {
      state.data.billingDetails = {
        ...state.data.billingDetails,
        ...action.payload,
      };
    },
    setBillingAddress(state, action) {
      state.data.billingDetails.billingAddress = {
        ...state.data.billingDetails.billingAddress,
        ...action.payload,
      };
    },
    setSignup(state, action) {
      state.data.signup = { ...state.data.signup, ...action.payload };
    },
    setName(state, action) {
      state.data.companyName = action.payload;
    },
    updateCart(state, action) {
      const { index, packageName, price } = action.payload;
      if (state.data.cart[index]) {
        state.data.cart[index] = { packageName, price };
      } else {
        state.data.cart.push({ packageName, price });
      }
    },
    addToCart(state, action) {
      state.data.cart.push(action.payload);
    },
    removeFromCart(state, action) {
      const index = action.payload;
      state.data.cart = state.data.cart.filter((_, i) => i !== index);
    },
  },
});

export const {
  setPackage,
  setBillingDetails,
  setBillingAddress,
  setSignup,
  setName,
  updateCart,
  addToCart,
  removeFromCart,
} = packageSlice.actions;

export default packageSlice.reducer;
