import React from "react";
import { Typography, Box, Button } from "@mui/material";
import { keyframes } from "@emotion/react";
import { useNavigate } from "react-router-dom";

// Keyframes for logo animation (rotate like a loading spinner)
const rotate = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const SuccessPage = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        textAlign: "center",
        mt: 10,
        mb: 8,
      }}
    >
      {/* Logo at the top */}
      {/* <Box
        component="img"
        src="https://via.placeholder.com/50" // Replace with your logo path
        alt="Logo"
        sx={{
          width: 50,
          height: 50,
          mb: 2,
        }}
      /> */}

      {/* Main text */}
      <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold" }}>
        You’re all set to incorporate your business.
      </Typography>

      {/* Highlighted text */}
      <Typography
        variant="h5"
        color="#10182C"
        gutterBottom
        sx={{ mt: 4, fontWeight: "medium" }}
      >
        We’re just setting you up on our Incorporation Platform.
      </Typography>

      <Button
        variant="contained"
        onClick={() => navigate("/company")}
        sx={{
          backgroundColor: "#10182C",
          borderRadius: "20px",
          // padding: "0.75rem 2rem",
          fontSize: "1.25rem",
          fontWeight: "bold",
          marginTop: "1.5rem",
          textTransform: "none",
          marginBottom: "0.25rem",
          ":hover": {
            backgroundColor: "#5500CC",
          },
        }}
      >
        {"Proceed to filling up the forms >>"}
      </Button>
    </Box>
  );
};

export default SuccessPage;
