import React, { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import BackButton from "../../assets/logo/BackButton.svg";

const DirectorQuestion = ({ handleClickSetTwo, handlePrevious }) => {
  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (newSelection) => {
    setSelectedOption(newSelection);
    handleClickSetTwo(newSelection);
  };

  return (
    // <Box
    //   sx={{
    //     display: "flex",
    //     flexDirection: "column",
    //     padding: "6rem",
    //     paddingLeft:'0rem',
    //     alignItems: "center",
    //     textAlign: "left",
    //   }}
    // >
    //   <Box>
    //     <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
    //       Will you be a Director of this business?
    //     </Typography>

    //     <Typography
    //       variant="body1"
    //       sx={{
    //         fontSize: "1.2rem",
    //         color: "#5A5A5A",
    //         mb: 4,
    //         maxWidth: "870px",
    //         width: "100%",
    //       }}
    //     >
    //       We require that you provide authorisation of the information provided.
    //     </Typography>

    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        textAlign: "left",
        padding: { xs: "0", md: "5rem" },
        paddingTop: { xs: "1rem" },
        maxWidth: { xs: "450px", md: "900px" },
      }}
    >
      <Box>
        <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
          Will you be a Director of this business?
        </Typography>

        {/* Subtitle */}
        <Typography
          variant="body1"
          sx={{
            fontSize: "1.2rem",
            color: "#5A5A5A",
            mb: 4,
            textAlign: "left",
          }}
        >
          We require that you provide authorisation of the information provided.
        </Typography>
        <Button
          variant="outlined"
          onClick={() => handleOptionChange("yes")}
          sx={{
            border: "2px solid",
            borderColor: selectedOption === "yes" ? "#10182C" : "#D1D1D1",
            borderRadius: "10px",
            width: { xs: "19rem", md: "38rem" },
            fontSize: "1rem",
            color: selectedOption === "yes" ? "#10182C" : "#5A5A5A",
            marginBottom: "1rem",
            padding: "0.9rem",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#181e54",
              color: "white",
            },
          }}
        >
          Yes, I will be a Director
        </Button>
        <br />
        {/* No Button */}
        <Button
          variant="outlined"
          onClick={() => handleOptionChange("no")}
          sx={{
            border: "2px solid",
            marginBottom: "2rem",
            borderColor: selectedOption === "no" ? "#10182C" : "#D1D1D1",
            borderRadius: "10px",
            width: { xs: "19rem", md: "38rem" },
            fontSize: "1rem",
            padding: "0.9rem",
            color: selectedOption === "no" ? "#10182C" : "#5A5A5A",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#181e54",
              color: "white",
            },
          }}
        >
          No, I’m completing this on behalf of someone else
        </Button>

        <Box display="flex">
          <Button
            // variant="contained"
            onClick={handlePrevious}
            sx={{
              color: "#000",
              fontSize: "1rem",
              textTransform: "none",
              marginBottom: "0.75rem",
              marginRight: "0.75rem",
            }}
          >
            <img width={"70px"} src={BackButton} />
          </Button>
          {/* <Button
            variant="contained"
            onClick={() => handleClickSetTwo(selectedOption)}
            sx={{
              backgroundColor: "#10182C",
              borderRadius: "20px",
              // // padding: "0.75rem 2rem",
              fontSize: "1.25rem",
              fontWeight: "bold",
              textTransform: "none",
              marginBottom: "0.75rem",
              ":hover": {
                backgroundColor: "#5500CC",
              },
            }}
          >
            Continue
          </Button> */}
        </Box>
      </Box>
    </Box>
  );
};

export default DirectorQuestion;
