import React from "react";
import { Box, Typography } from "@mui/material";
import userLogo from "../../assets/logo/userLogo.jpeg";

const DashboardHeader = () => {
  const profileName = localStorage.getItem("username");
  const profileEmail = localStorage.getItem("useremail");

  return (
    <Box sx={{ backgroundColor: "#FFFFFF", width: "100%" }}>
      <Box sx={{ padding: "1rem 3rem", display: "flex" }}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"flex-end"}
          sx={{ marginLeft: "auto" }}
        >
          <Typography
            variant="body1"
            sx={{ color: "#212B36", fontWeight: "500" }}
          >
            {profileName}
          </Typography>
          <Typography variant="body2" sx={{ color: "#637381" }}>
            {profileEmail}
          </Typography>
        </Box>
        <Box ml={1}>
          <img src={userLogo} alt="userlogo" width={"46px"} />
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardHeader;
