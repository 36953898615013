import React, { useState, useEffect } from "react";
import { Box, Button, Modal } from "@mui/material";
import {
  DocumentsApi,
  getDocumentsApi,
  editDocumentsApi,
} from "../../apiServices/services/DocumentsServices";
import { updateFormStatus } from "../../apiServices/services/ProfileServices";

const Documents = ({ onContinue, onBack }) => {
  const [formData, setFormData] = useState({
    articleOfAssociation: "",
    incorporationDocument: "",
    electronicCertificate: 0,
    printedDocument: false,
    boundCompanyResgister: false,
    notifyingLetter: false,
    officerConsent: false,
    userId: localStorage.getItem("userId"),
    isFormCompleted: false,
  });

  const [userId, setUserId] = useState(0);
  const [openArticles, setOpenArticles] = useState(false);
  const [openBespoke, setOpenBespoke] = useState(false);

  useEffect(() => {
    getDocumentsApi(formData.userId).then((response) => {
      if (response.attributes) {
        setUserId(response.id);
        setFormData((prevState) => ({
          ...prevState,
          articleOfAssociation: response.attributes.articleOfAssociation,
          incorporationDocument: response.attributes.incorporationDocument,
          electronicCertificate: response.attributes.electronicCertificate,
          printedDocument: response.attributes.printedDocument,
          boundCompanyResgister: response.attributes.boundCompanyResgister,
          notifyingLetter: response.attributes.notifyingLetter,
          officerConsent: response.attributes.officerConsent,
          isFormCompleted: response.attributes.isFormCompleted,
        }));
      }
    });
  }, []);

  const handleSelect = (index) => {
    setFormData({ ...formData, electronicCertificate: index });
  };

  const handleCheckboxChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.checked });
  };

  const handleRadioboxChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    if (!formData.isFormCompleted) {
      DocumentsApi({ ...formData, isFormCompleted: true }).then((res) => {
        if (res.status === 200) {
          updateFormStatus(formData.userId, { documentsFormStatus: true });
          onContinue();
          localStorage.setItem("activeForm", "documentsFormStatus");
        }
      });
    } else {
      editDocumentsApi(userId, { ...formData }).then((res) => {
        if (res.status === 200) {
          onContinue();
          localStorage.setItem("activeForm", "documentsFormStatus");
        }
      });
    }
  };

  const handleOpenArticles = (event) => {
    event.stopPropagation();
    setOpenArticles(true);
  };

  const handleOpenBespoke = (event) => {
    event.stopPropagation();
    setOpenBespoke(true);
  };

  const handleClose = () => {
    setOpenArticles(false);
    setOpenBespoke(false);
  };

  const options = [
    "Online Basic",
    "Online Classic",
    "Online Bold",
    "Online Diamond",
    "Online Modern",
    "Online Traditional",
    "Online Contemporary",
    "Online Vintage",
    "Online Vibrant",
  ];

  return (
    <div className="w-full h-auto pb-8 bg-white" style={{ color: "#2F2D3B" }}>
      <div className="font-bold text-xl py-4 px-10">Other options</div>
      <div className="w-full h-[2px] bg-gray-100 mb-4"></div>
      <div className="mb-6 px-10">Select from the various options below:</div>
      <div className="mb-6 px-10">
        <h3 className="text-md font-bold mb-2">Articles of association</h3>
        <h2 className="font-medium mb-6">
          Select the articles of association you want to use for the company
        </h2>
        <div className="flex items-center mb-4">
          <input
            type="radio"
            name="articleOfAssociation"
            value="modelArticles"
            checked={formData.articleOfAssociation === "modelArticles"}
            id="modelArticles"
            className="mr-2 h-4 w-4"
            onChange={handleRadioboxChange}
          />
          <label htmlFor="modelArticles" className="flex-grow">
            Model articles of association
          </label>
          <span className="text-[#64748B] mr-4">No extra cost</span>
          {/* <a href="#" className="ml-2 text-[#0F0049] font-medium underline">
            More details
          </a> */}
        </div>
        <div className="flex items-center mb-4">
          <input
            type="radio"
            value="advancedArticles"
            name="articleOfAssociation"
            id="advancedArticles"
            className="mr-2 h-4 w-4"
            checked={formData.articleOfAssociation === "advancedArticles"}
            onChange={handleRadioboxChange}
          />
          <label htmlFor="advancedArticles" className="flex-grow">
            Advanced model articles of association for 1 share class
          </label>
          <span className="text-[#64748B] mr-4">Additional £50.00 + VAT</span>
          <a
            onClick={handleOpenArticles}
            className="ml-2 text-[#0F0049] font-medium underline cursor-pointer"
          >
            More details
          </a>
        </div>
        <div className="flex items-center mb-4">
          <input
            type="radio"
            value="bespokeArticles"
            name="articleOfAssociation"
            id="bespokeArticles"
            className="mr-2 h-4 w-4"
            checked={formData.articleOfAssociation === "bespokeArticles"}
            onChange={handleRadioboxChange}
          />
          <label htmlFor="bespokeArticles" className="flex-grow">
            Bespoke articles of association
          </label>
          <span className="text-[#64748B] mr-4">No extra cost</span>
          <a
            onClick={handleOpenBespoke}
            className="ml-2 text-[#0F0049] font-medium underline cursor-pointer"
          >
            More details
          </a>
        </div>
      </div>

      {/* <div className="w-full h-[2px] bg-gray-100 mb-4 mt-14 mb-10"></div> */}

      {/* <div className="mb-6 px-10">
        <h3 className="text-md font-bold mb-2">
          Incorporation documents and registers
        </h3>
        <p className="mb-6">Select options you want from the list below:</p>
        <div className="flex items-center justify-between mb-4">
          <p className="mb-6">First board minutes - electronics </p>{" "}
          <span className="text-[#64748B]">
            Included in package{" "}
            <a href="#" className="ml-4 text-[#0F0049] font-medium underline">
              More details
            </a>
          </span>
        </div>
        <h4 className="text-md font-bold mb-10">
          Electronic share certificates
        </h4>
        <div className="grid grid-cols-3 gap-10">
          {options.map((option, index) => (
            <div
              key={index}
              className={`h-[30vh] flex flex-col justify-between p-4 border-2 border-dashed rounded-md cursor-pointer bg-[#EFF4FB] ${
                formData.electronicCertificate === index
                  ? "border-blue-500"
                  : "border-gray-300"
              }`}
              onClick={() => handleSelect(index)}
            >
              <div className="flex justify-end">
                <div
                  className={`w-5 h-5 mb-2 border-2 rounded-full flex items-center justify-center  ${
                    formData.electronicCertificate === index
                      ? "border-blue-500"
                      : "border-gray-300"
                  }`}
                >
                  {formData.electronicCertificate === index && (
                    <div className="w-2.5 h-2.5 bg-blue-500 rounded-full"></div>
                  )}
                </div>
              </div>
              <div className="flex items-center justify-center">
                <span className="text-center w-[8.2rem] mb-4">
                  {option} share certificates
                </span>
              </div>
            </div>
          ))}
        </div>
      </div> */}

      <div className="mb-4 mt-10 px-10">
        {/* <div className="flex items-center mb-4">
          <input
            onChange={handleCheckboxChange}
            name="printedDocument"
            checked={formData.printedDocument}
            type="checkbox"
            id="printedDocuments"
            className="mr-4 w-4 h-4"
          />
          <label htmlFor="printedDocuments" className="flex-grow">
            Printed incorporation documents
          </label>
          <span className="text-[#64748B] mr-4">Additional £34.99 + VAT</span>
          <a href="#" className="ml-2 text-[#0F0049] font-medium underline">
            More details
          </a>
        </div>
        <div className="flex items-center mb-4">
          <input
            onChange={handleCheckboxChange}
            name="boundCompanyResgister"
            checked={formData.boundCompanyResgister}
            type="checkbox"
            id="boundCopy"
            className="mr-4 w-4 h-4"
          />
          <label htmlFor="boundCopy" className="flex-grow">
            Bound company registers
          </label>
          <span className="text-[#64748B] mr-4">Additional £24.99 + VAT</span>
          <a href="#" className="ml-2 text-[#0F0049] font-medium underline">
            More details
          </a>
        </div>
        <div className="flex items-center mb-4">
          <input
            onChange={handleCheckboxChange}
            name="notifyingLetter"
            checked={formData.notifyingLetter}
            type="checkbox"
            id="legalAdvising"
            className="mr-4 w-4 h-4"
          />
          <label htmlFor="legalAdvising" className="flex-grow">
            Legal assistance with HC01 to change a company's de...
          </label>
          <span className="text-[#64748B] mr-4">Additional £9.99 + VAT</span>
          <a href="#" className="ml-2 text-[#0F0049] font-medium underline">
            More details
          </a>
        </div>
        <div className="flex items-center">
          <input
            onChange={handleCheckboxChange}
            name="officerConsent"
            checked={formData.officerConsent}
            type="checkbox"
            id="other"
            className="mr-4 w-4 h-4"
          />
          <label htmlFor="other" className="flex-grow">
            Other (comment or ask below)
          </label>
          <span className="text-[#64748B] mr-4">No extra cost</span>
          <a href="#" className="ml-2 text-[#0F0049] font-medium underline">
            More details
          </a>
        </div> */}

        <Modal
          open={openArticles}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 600,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <h2 className="font-bold underline mb-4">
              Advanced model articles of association for 1 share class
            </h2>
            <p className="mb-2">
              RiseLegal will produce a set of articles of association for 1
              share class with 1 director. These are different to the Model
              Articles of Association provided by Companies House and protect
              you as a shareholder. The fee to complete this is £50 + VAT.
            </p>
          </Box>
        </Modal>

        <Modal
          open={openBespoke}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 600,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <h2 className="font-bold underline mb-4">
              Bespoke articles of association
            </h2>
            <p className="mb-2">
              RiseLegals will produce a bespoke set of articles of association.
              Typically, companies with various share classes, or different
              terms between shareholders will require a bespoke set of Articles
              of Association. The fee to complete this is £600 + VAT.
            </p>
          </Box>
        </Modal>

        <Box display="flex">
          <Button
            sx={{
              marginTop: "1rem",
              textTransform: "none",
              backgroundColor: "#ffffff",
              color: "#cfcfcf",
              border: "1px solid #cfcfcf",
              borderRadius: "1rem",
              padding: "0.4rem 1.5rem",
              fontWeight: 600,
              "&:hover": {
                color: "#2069F8",
                border: "1px solid #2069F8",
              },
            }}
            onClick={onBack}
          >
            Previous
          </Button>
          <Button
            sx={{
              marginTop: "1rem",
              marginLeft: "0.5rem",
              borderRadius: "1rem",
              backgroundColor: "#2069F8",
              color: "#ffffff",
              fontWeight: 700,
              padding: "0.5rem 1.5rem",
              textTransform: "none",
              width: "max-content",
              "&:hover": {
                backgroundColor: "#b4a4f5",
              },
            }}
            onClick={handleSubmit}
          >
            Continue
          </Button>
        </Box>
      </div>
    </div>
  );
};

export default Documents;
