import React, { useState } from "react";
import { Box, Typography, Button, Grid } from "@mui/material";
import DirectorQuestion from "./DirectorQuestion";
import HomeAddressPrivacy from "./HomeAddressPrivacy";
import ChooseBankAccount from "./ChooseBankAccount";
import ReviewApplication from "./ReviewApplication";
import BussinessAdvisory from "./BussinessAdvisory";
import AccountCreationForm from "./AccountCreationForm";
import VerificationCodeForm from "./VerificationCodeForm";
import SuccessPage from "./SuccessPage";
import { checkoutToStripePage } from "../../apiServices/services/CheckoutServices";
import Notification from "../../Components/Notification";
import PlatformLogo from "../../assets/logo/PlatformLogo.svg";
import { useLocation } from "react-router-dom";

const IncorporationSteps = () => {
  const [step, setStep] = useState(1);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const companyName = queryParams.get("companyName");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    companyName: companyName,
    applicationReview: "",
    businessSupport: "",
    riseFormationAddress: "",
    freeCallArrangement: "",
  });

  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "",
  });

  const handlePreviousToOne = () => {                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    
    setStep(1);
  };

  const handleContinue = () => {
    setStep(2);
  };

  const handleClickSetTwo = (selectedOption) => {
    setStep(3);
  };

  const handleClickSetThree = (selectedOption) => {
    setFormData((prevData) => ({
      ...prevData,
      riseFormationAddress:
        selectedOption === "useRiseFormations"
          ? "Yes, I will use RiseFomations Address (+£89 + VAT / year)"
          : "no",
    }));
    setStep(4);
  };

  const handleClickSetFour = (selectedOption) => {
    setFormData((prevData) => ({
      ...prevData,
      businessSupport:
        selectedOption === "useRiseFormations"
          ? "Yes, I’d like access to Business Bank Support"
          : "no",
    }));
    setStep(5);
  };

  const handleClickSetFive = (selectedOption) => {
    setFormData((prevData) => ({
      ...prevData,
      applicationReview:
        selectedOption === "yes"
          ? "Yes, please review my application (+£20 + VAT)"
          : "no",
    }));
    setStep(6);
  };

  const handleClickSetSix = (selectedOption) => {
    setFormData((prevData) => ({
      ...prevData,
      freeCallArrangement:
        selectedOption === "useRiseFormations"
          ? "Yes, I’d like to arrange a free 30-minute call"
          : "no",
    }));
    setStep(7);
  };

  const handleClickSetSeven = (data) => {
    const { firstName, lastName, email, phoneNumber, password } = data;
    setFormData((prevData) => ({
      ...prevData,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phoneNumber: phoneNumber,
      password: password,
    }));
    setStep(8);
  };

  const handleClickSetEight = () => {
    checkoutToStripePage({ data: formData })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.url !== undefined) {
            window.location.href = res.data.url;
          } else {
            setNotification({
              open: true,
              message: res.data.message,
              severity: "error",
            });
          }
        }
      })
      .catch((err) => console.log("err in checkoutToStripePage : ", err));
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Grid
        maxWidth={"1440px"}
        width={"80%"}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        // mt={4}
        sx={{
          paddingTop: "1rem",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {step === 1 ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: { xs: "1rem", md: "4rem" },
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "left",
                  width: "55vw",
                  ml: { xs: 6 },
                }}
              >
                <Box>
                  <Typography variant="h3" sx={{ fontWeight: "bold", mb: 2 }}>
                    Let’s Incorporate your new company!
                  </Typography>

                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "1.2rem",
                      color: "#5A5A5A",
                      mb: 4,
                      width: { xs: "80vw", md: "40vw" },
                    }}
                  >
                    Answer a few questions about your business and we’ll suggest
                    the best services to get your started.
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1.5rem",
                      mb: 5,
                    }}
                  >
                    {[
                      "Tell us some basic information about your business",
                      "Access some of our benefits",
                      "Review and check out",
                    ].map((text, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "1rem",
                        }}
                      >
                        <Box
                          sx={{
                            width: { xs: "30px", md: "40px" },
                            height: { xs: "30px", md: "40px" },
                            borderRadius: "50%",
                            border: "2px solid #2069F8",
                            backgroundColor: "#2069F8",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "#fff",
                            fontWeight: "bold",
                            fontSize: { xs: "1rem", md: "1.2rem" },
                          }}
                        >
                          {index + 1}
                        </Box>
                        <Typography
                          variant="body1"
                          sx={{
                            // fontWeight: "500",
                            fontSize: { xs: "0.9rem", md: "1rem" },
                            color: "#000",
                          }}
                        >
                          {text}
                        </Typography>
                      </Box>
                    ))}
                  </Box>

                  <Button
                    variant="contained"
                    onClick={handleContinue}
                    sx={{
                      backgroundColor: "#2069F8",
                      borderRadius: "20px",
                      fontSize: "1.25rem",
                      color: "#fff",
                      fontWeight: "bold",
                      textTransform: "none",
                      ":hover": {
                        backgroundColor: "#181e54",
                      },
                    }}
                  >
                    Continue
                  </Button>
                </Box>
              </Box>
            ) : /* Other step components remain the same */
            step === 2 ? (
              <DirectorQuestion
                handleClickSetTwo={handleClickSetTwo}
                handlePrevious={() => setStep(1)}
              />
            ) : step === 3 ? (
              <HomeAddressPrivacy
                handleClickSetThree={handleClickSetThree}
                handlePrevious={() => setStep(2)}
              />
            ) : step === 4 ? (
              <ChooseBankAccount
                handleClickSetFour={handleClickSetFour}
                handlePrevious={() => setStep(3)}
              />
            ) : step === 5 ? (
              <ReviewApplication
                handleClickSetFive={handleClickSetFive}
                handlePrevious={() => setStep(4)}
              />
            ) : step === 6 ? (
              <BussinessAdvisory
                handleClickSetSix={handleClickSetSix}
                handlePrevious={() => setStep(5)}
              />
            ) : step === 7 ? (
              <AccountCreationForm
                handleClickSetSeven={handleClickSetSeven}
                handlePrevious={() => setStep(6)}
              />
            ) : step === 8 ? (
              <VerificationCodeForm
                handlePrevious={() => setStep(7)}
                handleClickSetEight={handleClickSetEight}
                formData={formData}
              />
            ) : (
              <SuccessPage />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Notification
        open={notification.open}
        message={notification.message}
        onClose={handleCloseNotification}
        severity={notification.severity}
      />
    </Box>
  );
};

export default IncorporationSteps;
