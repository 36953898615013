import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Collapse,
  ListItemButton,
} from "@mui/material";
import Notification from "../../Components/Notification";
import CompanyDetail from "../Forms/CompanyDetail";
import Directors from "../Forms/Directors";
import TypeOfShares from "../Forms/TypeOfShares";
import ShareHolding from "../Forms/ShareHolding";
import Document from "../../Components/Documents/Documents";
import Banking from "../../Components/Banking/Banking";
import Summary from "../Forms/Summary";
import { sumbitDetails } from "../../apiServices/services/AuthServices";
import Button from "../../Components/Button/Button";
import CompletionTick from "../../assets/logo/completionTick.svg";
import SuccessPage from "../Forms/SuccessPage";

const DashboardIncorporation = () => {
  const [openParentItem, setOpenParentItem] = useState("companyDetails");
  const [openChildItem, setOpenChildItem] = useState("companyDetails");
  const [isCompanyDetailsCompleted, setIsCompanyDetailsCompleted] =
    useState(false);
  const [isDirectorsCompleted, setIsDirectorsCompleted] = useState(false);
  const [isTypeOfShareCompleted, setIsTypeOfShareCompleted] = useState(false);
  const [isShareHoldingCompleted, setIsShareHoldingCompleted] = useState(false);
  const [isBankingDetailsCompleted, setIsBankingDetailsCompleted] =
    useState(false);
  const [isSummaryCompleted, setIsSummaryCompleted] = useState(false);

  useEffect(() => {
    const activeForm = localStorage.getItem("activeForm");
    if (activeForm !== "null") {
      if (activeForm === "isCompanyDetailsCompleted") {
        setIsCompanyDetailsCompleted(true);
        setOpenParentItem("officeShareholders");
        setOpenChildItem("directors");
      } else if (activeForm === "isDirectorDetailsCompleted") {
        setIsCompanyDetailsCompleted(true);
        setIsDirectorsCompleted(true);
        setOpenParentItem("officeShareholders");
        setOpenChildItem("typeOfShares");
      } else if (activeForm === "isTypeOfShareDetailsCompleted") {
        setIsCompanyDetailsCompleted(true);
        setIsDirectorsCompleted(true);
        setIsTypeOfShareCompleted(true);
        setOpenParentItem("officeShareholders");
        setOpenChildItem("shareHolding");
      } else if (activeForm === "isShareholdingDetailsCompleted") {
        setIsCompanyDetailsCompleted(true);
        setIsDirectorsCompleted(true);
        setIsTypeOfShareCompleted(true);
        setIsShareHoldingCompleted(true);
        setOpenParentItem("documentsExtras");
        setOpenChildItem("banking");
      } else if (activeForm === "isBankingDetailsCompleted") {
        setIsCompanyDetailsCompleted(true);
        setIsDirectorsCompleted(true);
        setIsTypeOfShareCompleted(true);
        setIsShareHoldingCompleted(true);
        setIsBankingDetailsCompleted(true);
        setOpenParentItem("summary");
        setOpenChildItem("");
      } else if (activeForm === "isFormCompleted") {
        setIsCompanyDetailsCompleted(true);
        setIsDirectorsCompleted(true);
        setIsTypeOfShareCompleted(true);
        setIsShareHoldingCompleted(true);
        setIsBankingDetailsCompleted(true);
        setIsSummaryCompleted(true);
      }
    } else {
      setOpenParentItem("companyDetails");
      setOpenChildItem("companyDetails");
    }
  }, []);

  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const [formData, setFormData] = useState({
    companyDetails: {
      country: "UK",
      isDirector: true,
    },
    registeredOffice: {
      officeAddress: "",
      postcode: "",
      addressLine1: "",
      addressLine2: "",
      town: "",
      region: "",
      country: "EN",
      registrationNumber: "",
      useRegisteredAddress: false,
      gdprConsent: false,
      termsConsent: false,
      authCode: "",
      plcNumber: "",
      registrationEmail: "",
    },
    shareDetails: {
      shareClasses: [
        {
          shareClass: "Ordinary",
          currency: "GBP",
          nominalValue: "",
          particulars: "",
          typeOfShare: "",
        },
      ],
    },
    shareholders: {
      shareHolder: [
        {
          name: "",
          isDir: false,
          isSec: false,
          noOfShares: 0,
          isPsc: false,
        },
      ],
      userId: "",
      isFormCompleted: false,
    },
    role: {
      personType: "Individual",
      roles: {
        Director: false,
        Secretary: false,
        Shareholder: false,
        PSC: false,
      },
    },
    details: {
      title: "",
      forename: "",
      middleNames: "",
      surname: "",
      otherName: "no",
      dateOfBirth: "",
      nationality: "",
      businessOccupation: "",
      gdprConsent: false,
    },
    address: {
      residentialAddress: "",
      country: "England",
      exemptionGranted: "no",
      serviceAddress: "same",
    },
    shareHolding: {
      shareClass: "",
      currency: "GBP",
      numberOfShares: "",
      amountPerShare: "",
      totalAmount: "",
      sharePremium: "",
    },
    significantControl: {
      reason1: "no",
      reason2: "no",
      reason3: "no",
      reason4: "no",
      trustControl: false,
      firmControl: false,
      pscConfirm: false,
    },
    documents: {
      articleOfAssociation: "",
      incorporationDocument: "",
      electronicCertificate: 0,
      printedDocument: false,
      boundCompanyResgister: false,
      notifyingLetter: false,
      officerConsent: false,
    },
    banking: {
      barclaysBank: true,
      barclaysContactPerson: "",
      address: "",
      contactEmail: "",
      contactLandline: null,
      contactMobile: null,
    },
    extras: {
      sameDayService: false,
      certificate: false,
      companySeal: false,
      companyStamp: false,
      companyNamePlate: false,
      domainName: false,
    },
  });

  const handleContinue = (currentParent, nextParent, nextChild) => {
    if (currentParent === "companyDetails") {
      setIsCompanyDetailsCompleted(true);
    }
    if (currentParent === "directors") {
      setIsDirectorsCompleted(true);
    }
    if (currentParent === "typeOfShares") {
      setIsTypeOfShareCompleted(true);
    }
    if (currentParent === "shareHolding") {
      setIsShareHoldingCompleted(true);
    }
    if (currentParent === "banking") {
      setIsBankingDetailsCompleted(true);
    }
    setOpenParentItem(nextParent);
    setOpenChildItem(nextChild);
    setNotification({
      open: true,
      message: "Previous form successfully saved!",
      severity: "success",
    });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  const handleBack = (prevParent, prevChild) => {
    setOpenParentItem(prevParent);
    setOpenChildItem(prevChild);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleSubmit = (data) => {
    // sumbitDetails(JSON.stringify(data)).then((res) => {
    //   if (res.status === 200) {
    //     setOpenParentItem("delivery");
    //   }
    // });
    setIsSummaryCompleted(true);
    setOpenParentItem("delivery");
  };

  return (
    <Box
      width={"100%"}
      sx={{
        marginTop: "2px",
        height: "calc(100vh - 5.3rem)",
      }}
      display={"flex"}
    >
      <Box
        width={"20%"}
        sx={{
          backgroundColor: "#FFFFFF",
          padding: "2rem 1rem",
          fontWeight: "bold",
        }}
      >
        <List component="nav">
          <ListItem disablePadding>
            <ListItemButton sx={{ padding: "0.5rem", cursor: "default" }}>
              {isCompanyDetailsCompleted ? (
                <Box>
                  <img
                    width={"27px"}
                    style={{ marginRight: "7px" }}
                    src={CompletionTick}
                  />
                </Box>
              ) : (
                <Button
                  isActive={openChildItem === "companyDetails"}
                  value="1"
                />
              )}
              <ListItemText
                primary="Company Details"
                sx={{
                  color:
                    openChildItem === "companyDetails"
                      ? "#3056D3"
                      : isCompanyDetailsCompleted
                      ? "#3056D3"
                      : "#64748B",
                  marginLeft: "0.25rem",
                  fontSize: "0.875rem !important",
                }}
                primaryTypographyProps={{
                  sx: {
                    fontWeight: "medium",
                  },
                }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton sx={{ padding: "0.5rem", cursor: "default" }}>
              {isDirectorsCompleted ? (
                <Box>
                  <img
                    width={"27px"}
                    style={{ marginRight: "7px" }}
                    src={CompletionTick}
                  />
                </Box>
              ) : (
                <Button isActive={openChildItem === "directors"} value="2" />
              )}
              <ListItemText
                primary="Directors"
                sx={{
                  color:
                    openChildItem === "directors"
                      ? "#3056D3"
                      : isDirectorsCompleted
                      ? "#3056D3"
                      : "#64748B",
                  marginLeft: "0.25rem",
                  fontSize: "0.875rem !important",
                }}
                primaryTypographyProps={{
                  sx: {
                    fontWeight: "medium",
                  },
                }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton sx={{ padding: "0.5rem", cursor: "default" }}>
              {isTypeOfShareCompleted ? (
                <Box>
                  <img
                    width={"27px"}
                    style={{ marginRight: "7px" }}
                    src={CompletionTick}
                  />
                </Box>
              ) : (
                <Button isActive={openChildItem === "typeOfShares"} value="3" />
              )}
              <ListItemText
                primary="Type of Shares"
                sx={{
                  color:
                    openChildItem === "typeOfShares"
                      ? "#3056D3"
                      : isTypeOfShareCompleted
                      ? "#3056D3"
                      : "#64748B",
                  marginLeft: "0.25rem",
                  fontSize: "0.875rem !important",
                }}
                primaryTypographyProps={{
                  sx: {
                    fontWeight: "medium",
                  },
                }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton sx={{ padding: "0.5rem", cursor: "default" }}>
              {isShareHoldingCompleted ? (
                <Box>
                  <img
                    width={"27px"}
                    style={{ marginRight: "7px" }}
                    src={CompletionTick}
                  />
                </Box>
              ) : (
                <Button isActive={openChildItem === "shareHolding"} value="4" />
              )}
              <ListItemText
                primary="Share Holdings"
                sx={{
                  color:
                    openChildItem === "shareHolding"
                      ? "#3056D3"
                      : isShareHoldingCompleted
                      ? "#3056D3"
                      : "#64748B",
                  marginLeft: "0.25rem",
                  fontSize: "0.875rem !important",
                }}
                primaryTypographyProps={{
                  sx: {
                    fontWeight: "medium",
                  },
                }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton sx={{ padding: "0.5rem", cursor: "default" }}>
              {isBankingDetailsCompleted ? (
                <Box>
                  <img
                    width={"27px"}
                    style={{ marginRight: "7px" }}
                    src={CompletionTick}
                  />
                </Box>
              ) : (
                <Button isActive={openChildItem === "banking"} value="5" />
              )}
              <ListItemText
                primary="Banking"
                sx={{
                  color:
                    openChildItem === "banking"
                      ? "#3056D3"
                      : isBankingDetailsCompleted
                      ? "#3056D3"
                      : "#64748B",
                  marginLeft: "0.25rem",
                  fontSize: "0.875rem !important",
                }}
                primaryTypographyProps={{
                  sx: {
                    fontWeight: "medium",
                  },
                }}
              />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton sx={{ padding: "0.5rem", cursor: "default" }}>
              {isSummaryCompleted ? (
                <Box>
                  <img
                    width={"27px"}
                    style={{ marginRight: "7px" }}
                    src={CompletionTick}
                  />
                </Box>
              ) : (
                <Button isActive={openParentItem === "summary"} value="6" />
              )}
              <ListItemText
                primary="Summary"
                sx={{
                  color:
                    openParentItem === "summary"
                      ? "#3056D3"
                      : isSummaryCompleted
                      ? "#3056D3"
                      : "#64748B",
                  marginLeft: "0.25rem",
                  fontSize: "0.875rem !important",
                }}
                primaryTypographyProps={{
                  sx: {
                    fontWeight: "medium",
                  },
                }}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
      <Box
        width={"80%"}
        p={3}
        sx={{
          flexGrow: 1,
          overflowY: "scroll",
        }}
      >
        {openParentItem === "companyDetails" &&
          openChildItem === "companyDetails" && (
            <CompanyDetail
              onContinue={() =>
                handleContinue(
                  "companyDetails",
                  "officeShareholders",
                  "directors"
                )
              }
            />
          )}
        {openParentItem === "officeShareholders" &&
          openChildItem === "directors" && (
            <Directors
              onContinue={() =>
                handleContinue(
                  "directors",
                  "officeShareholders",
                  "typeOfShares"
                )
              }
              onBack={() => handleBack("companyDetails", "companyDetails")}
            />
          )}
        {openParentItem === "officeShareholders" &&
          openChildItem === "typeOfShares" && (
            <TypeOfShares
              onContinue={() =>
                handleContinue(
                  "typeOfShares",
                  "officeShareholders",
                  "shareHolding"
                )
              }
              onBack={() => handleBack("officeShareholders", "directors")}
            />
          )}
        {openParentItem === "officeShareholders" &&
          openChildItem === "shareHolding" && (
            <ShareHolding
              onContinue={() =>
                handleContinue("shareHolding", "documentsExtras", "banking")
              }
              onBack={() => handleBack("officeShareholders", "typeOfShares")}
            />
          )}
        {openParentItem === "documentsExtras" &&
          openChildItem === "documents" && (
            <Document
              onContinue={() => handleContinue("documentsExtras", "banking")}
              onBack={() =>
                handleBack("officeShareholders", "significantControl")
              }
            />
          )}
        {openParentItem === "documentsExtras" &&
          openChildItem === "banking" && (
            <Banking
              onContinue={() => handleContinue("banking", "summary", "")}
              onBack={() => handleBack("officeShareholders", "shareHolding")}
            />
          )}
        {openParentItem === "summary" && (
          <Summary
            onContinue={() => handleSubmit()}
            onBack={() => handleBack("documentsExtras", "banking")}
          />
        )}
        {openParentItem === "delivery" && (
          <SuccessPage onBack={() => handleBack("summary", "")} />
        )}
      </Box>
      <Notification
        open={notification.open}
        message={notification.message}
        onClose={handleCloseNotification}
        severity={notification.severity}
      />
    </Box>
  );
};

export default DashboardIncorporation;
