import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import DashboardHeader from "../../Components/DashboardHeader/DashboardHeader";

const DashboardLayout = () => {
  const navigate = useNavigate();

  const isAuthenticated = Boolean(localStorage.getItem('token'));

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login', { replace: true });
    }
  }, [isAuthenticated, navigate]);

  if (!isAuthenticated) {
    return null;
  }

  return (
    <Box
      display={"flex"}
      sx={{
        height: "100vh",
        backgroundColor: "#F6F5F8",
        color: "#2F2D3B",
      }}
    >
      <Box sx={{
        flexGrow: 1
      }}>
        <Sidebar />
      </Box>
      <Box
        sx={{
          width: "85%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <DashboardHeader />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            overflowY: "scroll",
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardLayout;
