import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Select,
  MenuItem,
  FormControl,
  Divider,
  TextField,
  Grid,
  Checkbox,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  ShareTypesApi,
  getShareTypesApi,
  editShareTypesApi,
} from "../../apiServices/services/OfficeServices";
import {
  getRegisteredDetailsApi,
  PostCodeApi,
} from "../../apiServices/services/CompanyServices";
import { updateFormStatus } from "../../apiServices/services/ProfileServices";

const TypeOfShares = ({ onContinue, onBack }) => {
  const [formData, setFormData] = useState({
    shares: ["Ordinary"], // Pre-fill the first share with "Ordinary"
    userId: localStorage.getItem("userId"),
    isFormCompleted: false,
  });

  const [userId, setUserId] = useState(0);

  const typeOfShares = [
    "Ordinary",
    "Preference",
    "Cumulative Preference",
    "Redeemable",
    "Cumulative Redeemable Preference",
    "Deferred",
    "Other",
  ];

  const handleChangeClass = (index, event) => {
    const { value } = event.target;
    const newShares = formData.shares.map((share, i) =>
      i === index ? value : share
    );
    setFormData((prevFormData) => ({
      ...prevFormData,
      shares: newShares,
    }));
  };

  const getFilteredShares = (index) => {
    const selectedShares = formData.shares.filter((_, i) => i !== index);

    return typeOfShares.filter(
      (shareType) => !selectedShares.includes(shareType)
    );
  };

  const handleAddNewClass = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      shares: [...prevFormData.shares, ""], // Adding an empty string instead of an array
    }));
  };

  const handleRemoveShares = () => {
    setFormData((prev) => ({
      ...prev,
      shares: prev.shares.slice(0, -1),
    }));
  };

  useEffect(() => {
    getShareTypesApi(formData.userId).then((response) => {
      if (response?.attributes?.results[0]) {
        const sharesData = response.attributes.results[0].shares;
        setUserId(response?.attributes?.results[0]?.id);
        setFormData((prevState) => ({
          ...prevState,
          shares: sharesData,
          isFormCompleted: response.attributes.results[0].isFormCompleted,
        }));
      }
    });
  }, []);

  const handleSubmit = () => {
    for (let i = 0; i < formData.shares.length; i++) {
      if (!formData.shares[i]) {
        alert(`Please fill all the details for Shares ${i + 1}`);
        return;
      }
    }
    if (!formData.isFormCompleted) {
      const formDataToSend = {
        ...formData,
        shares: formData.shares.flat(),
        isFormCompleted: true,
      };

      ShareTypesApi(formDataToSend).then((res) => {
        if (res.status === 200) {
          updateFormStatus(formData.userId, {
            isTypeOfShareDetailsCompleted: true,
          }).then((res) => {
            if (res.status === 200) {
              onContinue();
              localStorage.setItem(
                "activeForm",
                "isTypeOfShareDetailsCompleted"
              );
            }
          });
        }
      });
    } else {
      editShareTypesApi(userId, { ...formData }).then((res) => {
        if (res.status === 200) {
          onContinue();
          localStorage.setItem("activeForm", "isTypeOfShareDetailsCompleted");
        }
      });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        p: 3,
        backgroundColor: "#fff",
        borderRadius: 2,
        boxShadow: 1,
      }}
    >
      <Typography
        variant="h6"
        sx={{
          color: "1C2434",
          fontWeight: "bold",
          fontSize: "1.25rem",
        }}
      >
        Share Class
      </Typography>

      <Box component="form" noValidate autoComplete="off">
        <Typography
          variant="body1"
          sx={{
            fontSize: "1rem",
            fontWeight: "medium",
            color: "2F2D3B",
            mb: 2,
            mt: 2,
            maxWidth: "650px",
            width: "100%",
          }}
        >
          Most businesses will incorporate a company with Ordinary share/s. If
          you need to change your share class later, you can always speak with
          our legal team and we’ll be sure to update accordingly. ​
        </Typography>
        <Box sx={{ mb: 2 }}>
          {formData.shares.map((share, index) => (
            <FormControl
              sx={{
                mb: 2,
                width: "100%",
                maxWidth: "550px",
                marginRight: "2rem",
                mt: 1,
              }}
              key={index}
            >
              <Typography
                variant="h6"
                sx={{
                  color: "1C2434",
                  fontWeight: "bold",
                  fontSize: "1.25rem",
                }}
              >
                Share Class {index + 1}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "1rem",
                  fontWeight: "medium",
                  color: "#2F2D3B",
                  marginTop: "0.5rem",
                  marginBottom: "1rem",
                }}
              >
                Type Of Share
              </Typography>
              <Select
                value={share}
                onChange={(e) => handleChangeClass(index, e)}
                displayEmpty
                renderValue={(selected) =>
                  selected || (
                    <span style={{ color: "gray" }}>Choose Share Type</span>
                  )
                }
              >
                {getFilteredShares(index).map((shareType) => (
                  <MenuItem key={shareType} value={shareType}>
                    {shareType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ))}
        </Box>
        {formData.shares.length < 3 && (
          <Typography
            variant="body1"
            sx={{
              fontSize: "1.1rem",
              fontWeight: "bold",
              color: "#2F2D3B",
              maxWidth: "745px",
              width: "100%",
              mb: 1,
            }}
          >
            Need to add other type of share class?​
          </Typography>
        )}{" "}
        <Box sx={{ display: "flex", width: "730px", mt: 2 }}>
          {formData.shares.length < 3 && (
            <Typography
              variant="body1"
              sx={{
                display: "inline",
                fontSize: "0.9rem",
                fontWeight: "medium",
                color: "#2069F7",
                maxWidth: "745px",
                width: "100%",
                cursor: "pointer",
              }}
              onClick={() => handleAddNewClass()}
            >
              + New Share Class
            </Typography>
          )}
          {formData.shares.length > 1 && (
            <Typography
              variant="body1"
              sx={{
                display: "inline",
                fontSize: "0.9rem",
                fontWeight: "medium",
                color: "#2069F7",
                maxWidth: "745px",
                width: "100%",
                cursor: "pointer",
              }}
              onClick={() => handleRemoveShares()}
            >
              - Remove Share Class
            </Typography>
          )}
        </Box>
        <Box mt={3} display="flex">
          <Button
            sx={{
              marginTop: "1rem",
              textTransform: "none",
              backgroundColor: "#ffffff",
              color: "#cfcfcf",
              border: "1px solid #cfcfcf",
              borderRadius: "1rem",
              padding: "0.4rem 1.5rem",
              fontWeight: 600,
              "&:hover": {
                color: "#2069F8",
                border: "1px solid #2069F8",
              },
            }}
            onClick={onBack}
          >
            Previous
          </Button>
          <Button
            sx={{
              marginTop: "1rem",
              marginLeft: "0.5rem",
              borderRadius: "1rem",
              backgroundColor: "#2069F8",
              color: "#ffffff",
              fontWeight: 700,
              padding: "0.5rem 1.5rem",
              textTransform: "none",
              width: "max-content",
              "&:hover": {
                backgroundColor: "#2069F8",
              },
            }}
            onClick={handleSubmit}
          >
            Continue
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default TypeOfShares;
