import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  TextField,
  MenuItem,
  Button,
  Grid,
  Divider,
  FormControl,
  Select,
} from "@mui/material";
import TickMark from "../../assets/logo/TickMark.svg";
import {
  ShareHoldingApi,
  getShareHoldingApi,
  editShareHoldingApi,
  getShareTypesApi,
} from "../../apiServices/services/OfficeServices";
import { updateFormStatus } from "../../apiServices/services/ProfileServices";
import { getDirectorsApi } from "../../apiServices/services/OfficeServices";

const ShareHolding = ({ onContinue, onBack }) => {
  const [formData, setFormData] = useState({
    initialPaidCapital: "",
    shareHolders: [
      {
        name: "",
        noOfShares: "",
        typeOfShare: "",
      },
    ],
    isFormCompleted: false,
    userId: localStorage.getItem("userId"),
  });

  const shareHoldingRef = useRef(null);
  const [userId, setUserId] = useState(0);
  const [shareTypes, setShareTypes] = useState([""]);

  useEffect(() => {
    getShareTypesApi(formData.userId).then((response) => {
      if (response?.attributes?.results?.[0]) {
        setShareTypes(response.attributes.results[0].shares);
      }
    });

    getShareHoldingApi(formData.userId).then((response) => {
      const result = response?.attributes?.results?.[0];
      if (result) {
        // Populate formData if shareholding data exists
        setUserId(result.id);
        setFormData((prevState) => ({
          ...prevState,
          initialPaidCapital: result.initialPaidCapital,
          shareHolders: result.shareHolders,
          isFormCompleted: result.isFormCompleted,
        }));
      } else {
        // If no shareholding data, use directors API
        getDirectorsApi(formData.userId).then((response) => {
          const directorResult = response?.attributes?.results?.[0];
          const shareHolders = directorResult?.shareHolders ?? [];

          // Populate shareHolders based on directors API response
          setFormData((prevState) => ({
            ...prevState,
            shareHolders: shareHolders.map((shareholder, index) => ({
              name: `${shareholder?.firstName ?? ""} ${
                shareholder?.surName ?? ""
              }`.trim(),
              noOfShares: "",
              typeOfShare: "",
            })),
          }));
        });
      }
    });
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    console.log(shareTypes);
    if (!formData.initialPaidCapital) {
      alert(`Please fill Initial Paid capital for Shareholding`);
      return;
    }
    for (let i = 0; i < formData.shareHolders.length; i++) {
      console.log(formData.shareHolders[i]);
      const { name, noOfShares, typeOfShare } = formData.shareHolders[i];
      if (!name || !noOfShares || !typeOfShare) {
        alert(`Please fill all the details for Shareholder ${i + 1}`);
        return;
      }
    }
    if (!formData.isFormCompleted) {
      ShareHoldingApi({ ...formData, isFormCompleted: true }).then((res) => {
        if (res.status === 200) {
          updateFormStatus(formData.userId, {
            isShareholdingDetailsCompleted: true,
          }).then((res) => {
            if (res.status === 200) {
              onContinue();
              localStorage.setItem(
                "activeForm",
                "isShareholdingDetailsCompleted"
              );
            } else {
            }
          });
        }
      });
    } else {
      editShareHoldingApi(userId, { ...formData }).then((res) => {
        if (res.status === 200) {
          onContinue();
          localStorage.setItem("activeForm", "isShareholdingDetailsCompleted");
        }
      });
    }
  };

  const handleAddShareholding = () => {
    const newShareholding = {
      name: "",
      noOfShares: "",
      typeOfShare: "",
    };
    setFormData((prevFormData) => {
      const updatedShareHolding = [
        ...prevFormData.shareHolders,
        newShareholding,
      ];
      return { ...prevFormData, shareHolders: updatedShareHolding };
    });

    setTimeout(() => {
      if (shareHoldingRef.current) {
        shareHoldingRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };

  const handleRemoveShareholding = () => {
    setFormData((prev) => ({
      ...prev,
      shareHoldings: prev.shareHoldings.slice(0, -1),
    }));
  };

  const handleClassChange = (index, key, value) => {
    if (shareTypes.length === 1) {
      setFormData((prevFormData) => {
        const updatedShareClasses = prevFormData.shareHolders.map(
          (shareHolders, i) =>
            i === index
              ? { ...shareHolders, [key]: value, typeOfShare: shareTypes[0] }
              : shareHolders
        );
        return { ...prevFormData, shareHolders: updatedShareClasses };
      });
    } else {
      setFormData((prevFormData) => {
        const updatedShareClasses = prevFormData.shareHolders.map(
          (shareHolders, i) =>
            i === index ? { ...shareHolders, [key]: value } : shareHolders
        );
        return { ...prevFormData, shareHolders: updatedShareClasses };
      });
    }
  };

  const handleRemoveShareholder = (index) => {
    setFormData((prev) => ({
      ...prev,
      shareHolders: prev.shareHolders.slice(0, -1),
    }));
  };

  return (
    <Box
      sx={{
        padding: 4,
        backgroundColor: "#fff",
        borderRadius: 2,
        boxShadow: 1,
        margin: "auto",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          color: "1C2434",
          fontWeight: "bold",
          fontSize: "1.25rem",
        }}
      >
        Shareholding
      </Typography>
      {/* <Divider sx={{ my: 3 }} /> */}
      <Typography
        variant="body1"
        sx={{
          fontSize: "1rem",
          color: "2F2D3B",
          mb: 3,
          mt: 2,
          maxWidth: "555px",
          width: "100%",
        }}
      >
        Below we have provided the number of shareholders outlined in the
        previous Directors section.
      </Typography>
      <Typography
        variant="body1"
        sx={{
          fontSize: "1rem",
          color: "2F2D3B",
          mb: 1,
          mt: 2,
          fontWeight: "bold",
        }}
      >
        Initial Paid-up capital
      </Typography>
      <Typography
        variant="body1"
        sx={{
          fontSize: "1rem",
          color: "2F2D3B",
          mb: 3,
          mt: 1,
          maxWidth: "555px",
          width: "100%",
        }}
      >
        After incorporation, you must deposit at least £1 of paid-up capital
        into your company bank account. We recommend this remains at £1 for
        incorporation purposes. ​
      </Typography>
      <TextField
        fullWidth
        placeholder="£1"
        name="initialPaidCapital"
        value={formData?.initialPaidCapital}
        onChange={(e) =>
          setFormData((prevData) => ({
            ...prevData,
            initialPaidCapital: e.target.value,
          }))
        }
        type="number"
        sx={{ maxWidth: "555px", width: "100%", mb: "1rem" }}
      />
      <Typography
        variant="body1"
        sx={{
          fontSize: "1rem",
          color: "2F2D3B",
          mb: 1.5,
          mt: 0.5,
          fontWeight: "bold",
        }}
      >
        Shareholder 1
      </Typography>
      <TextField
        placeholder="Enter Name"
        name="name"
        value={formData.shareHolders[0].name}
        onChange={(e) => handleClassChange(0, "name", e.target.value)}
        sx={{ maxWidth: "260px", width: "100%", mb: "1rem", mr: 5 }}
      />
      <TextField
        placeholder="Enter No. of Shares"
        name="noOfShares"
        value={formData.shareHolders[0].noOfShares}
        onChange={(e) => handleClassChange(0, "noOfShares", e.target.value)}
        sx={{ maxWidth: "260px", width: "100%", mb: "1rem" }}
      />
      <Typography
        variant="body1"
        sx={{
          fontSize: "1rem",
          color: "2F2D3B",
          mb: 1,
          mt: 0.5,
          fontWeight: "medium",
        }}
      >
        Share Class
      </Typography>
      {shareTypes.length < 2 ? (
        <TextField
          placeholder="Ordinary"
          name="typeOfShare"
          value={shareTypes[0]}
          onChange={(e) => handleClassChange(0, "typeOfShare", e.target.value)}
          type="string"
          sx={{ maxWidth: "555px", width: "100%", mb: "1rem", mr: 5 }}
        />
      ) : (
        <Box sx={{ mb: 2 }}>
          <FormControl
            sx={{
              mb: 2,
              width: "100%",
              maxWidth: "550px",
              marginRight: "2rem",
              mt: 1,
            }}
          >
            <Select
              value={formData.shareHolders[0]?.typeOfShare || ""}
              onChange={(e) =>
                handleClassChange(0, "typeOfShare", e.target.value)
              }
              displayEmpty
              renderValue={(selected) =>
                selected || (
                  <span style={{ color: "gray" }}>Choose Share Type</span>
                )
              }
            >
              {shareTypes.map((share, index) => (
                <MenuItem key={share} value={share}>
                  {share}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}

      {formData.shareHolders.slice(1).map((cls, index) => (
        <Box key={index} ref={shareHoldingRef}>
          <Typography
            variant="body1"
            sx={{
              fontSize: "1rem",
              color: "2F2D3B",
              mb: 1.5,
              mt: 0.5,
              fontWeight: "bold",
            }}
          >
            Shareholder {index + 2}
          </Typography>
          <TextField
            placeholder="Enter Name"
            name="name"
            value={formData?.shareHolders[index + 1].name}
            onChange={(e) =>
              handleClassChange(index + 1, "name", e.target.value)
            }
            sx={{ maxWidth: "260px", width: "100%", mb: "1rem", mr: 5 }}
          />
          <TextField
            placeholder="Enter No. of Shares"
            name="noOfShares"
            value={formData?.shareHolders[index + 1].noOfShares}
            onChange={(e) =>
              handleClassChange(index + 1, "noOfShares", e.target.value)
            }
            sx={{ maxWidth: "260px", width: "100%", mb: "1rem" }}
          />
          <Typography
            variant="body1"
            sx={{
              fontSize: "1rem",
              color: "2F2D3B",
              mb: 1,
              mt: 0.5,
              fontWeight: "medium",
            }}
          >
            Share Class
          </Typography>
          {shareTypes.length < 2 ? (
            <TextField
              placeholder="Ordinary"
              name="typeOfShare"
              value={shareTypes[0]}
              onChange={(e) =>
                handleClassChange(index + 1, "typeOfShare", e.target.value)
              }
              type="string"
              sx={{ maxWidth: "555px", width: "100%", mb: "1rem", mr: 5 }}
            />
          ) : (
            <Box sx={{ mb: 2 }}>
              <FormControl
                sx={{
                  mb: 2,
                  width: "100%",
                  maxWidth: "550px",
                  marginRight: "2rem",
                  mt: 1,
                }}
              >
                <Select
                  value={formData.shareHolders[index + 1]?.typeOfShare || ""}
                  onChange={(e) =>
                    handleClassChange(index + 1, "typeOfShare", e.target.value)
                  }
                  displayEmpty
                  renderValue={(selected) =>
                    selected || (
                      <span style={{ color: "gray" }}>Choose Share Type</span>
                    )
                  }
                >
                  {shareTypes.map((share, index) => (
                    <MenuItem key={share} value={share}>
                      {share}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
        </Box>
      ))}

      {formData.shareHolders[0].noOfShares == "100" && (
        <Box sx={{ display: "flex", mt: 1 }}>
          {" "}
          <img src={TickMark} />
          <Typography sx={{ color: "#2069F8", fontWeight: "bold", ml: 1 }}>
            This person will be deemed a Person of Significant Control on
            Companies House​
          </Typography>
        </Box>
      )}

      {formData.shareHolders.length < 4 && (
        <Typography
          variant="body1"
          sx={{
            fontSize: "1.1rem",
            fontWeight: "bold",
            color: "#2F2D3B",
            maxWidth: "745px",
            width: "100%",
            mt: 2,
            mb: 1,
          }}
        >
          Need to add other Directors or Shareholders?​
        </Typography>
      )}
      <Box
        sx={{
          display: "flex",
          maxWidth: "550px",
          width: "100%",
          mt: 2,
          justifyContent: "space-between",
        }}
      >
        {formData.shareHolders.length < 4 && (
          <Typography
            variant="body1"
            sx={{
              fontSize: "0.9rem",
              fontWeight: "medium",
              color: "#2069F7",
              cursor: "pointer",
            }}
            onClick={() => handleAddShareholding()}
          >
            + Add Company Member​
          </Typography>
        )}
        {formData.shareHolders.length > 1 && (
          <Typography
            variant="body1"
            sx={{
              fontSize: "0.9rem",
              fontWeight: "medium",
              color: "#2069F7",
              cursor: "pointer",
            }}
            onClick={() => handleRemoveShareholder()}
          >
            - Remove Company Member
          </Typography>
        )}
      </Box>
      <Grid item xs={12}>
        <Box mt={3} display="flex">
          <Button
            sx={{
              marginTop: "1rem",
              textTransform: "none",
              backgroundColor: "#ffffff",
              color: "#cfcfcf",
              border: "1px solid #cfcfcf",
              borderRadius: "1rem",
              padding: "0.4rem 1.5rem",
              fontWeight: 600,
              "&:hover": {
                color: "#2069F8",
                border: "1px solid #2069F8",
              },
            }}
            onClick={onBack}
          >
            Previous
          </Button>
          <Button
            sx={{
              marginTop: "1rem",
              marginLeft: "0.5rem",
              borderRadius: "1rem",
              backgroundColor: "#2069F8",
              color: "#ffffff",
              fontWeight: 700,
              padding: "0.5rem 1.5rem",
              textTransform: "none",
              width: "max-content",
              "&:hover": {
                backgroundColor: "#2069F8",
              },
            }}
            onClick={handleSubmit}
          >
            Continue
          </Button>
        </Box>
      </Grid>
    </Box>
  );
};

export default ShareHolding;
