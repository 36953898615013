import React from "react";
import { Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import riseGroupLogo from "../../assets/logo/riseGroupLogo.png";
import "../../styles/style.css";
import { useNavigate } from "react-router-dom";
import { AppBar, Toolbar, Grid, Button, Link, Box } from "@mui/material";

const Header = () => {
  const location = useLocation();
  const isPackagesPage = location.pathname === "/packages";
  const navigate = useNavigate();

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: "#10182C", // Background color
        padding: "6px 0",
        marginTop: "30px",
        width: "80%",
        borderRadius: { xs: "0", md: "55px" },
      }}
      elevation={0}
    >
      <Toolbar
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0",
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: "1440px", // Constrain content to 1440px width
            margin: "0 auto",
            padding: "0 24px",
            display: "flex",
            justifyContent: "space-between", // Align left (logo) and right (buttons)
            alignItems: "center", // Vertically center content
            paddingLeft: { xs: "1rem", md: "8rem" },
          }}
        >
          {/* Logo Section */}
          <Link
            href="https://formations.therisegroup.co.uk/"
            sx={{ display: "block", width: "208px", height: "53px" }}
          >
            <img
              src="https://formations.therisegroup.co.uk/wp-content/uploads/2024/10/Logo-3.png"
              alt="Rise Formations"
              style={{ width: "100%", height: "100%" }}
            />
          </Link>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
