import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Link,
  FormControlLabel,
  Checkbox,
  Alert,
} from "@mui/material";
import { loginService } from "../../apiServices/services/AuthServices";
import riseGroupLogo from "../../assets/logo/riseGroupLogo.png";
import tri1 from "../../assets/logo/tri1.png";
import tri2 from "../../assets/logo/tri2.png";
import tri3 from "../../assets/logo/tri3.png";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/Header/Header";
import { getFormStatus } from "../../apiServices/services/ProfileServices";

const LoginPage = () => {
  const [email, setEmail] = useState(
    localStorage.getItem("registeredEmail") ?? ""
  );
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const order = [
    "username",
    "email",
    "provider",
    "password",
    "resetPasswordToken",
    "confirmationToken",
    "confirmed",
    "blocked",
    "role",
    "company",
    "isCompanyDetailsCompleted",
    "isDirectorDetailsCompleted",
    "isTypeOfShareDetailsCompleted",
    "isShareholdingDetailsCompleted",
    "isBankingDetailsCompleted",
    "isFormCompleted",
  ];

  const navigate = useNavigate();

  const naviagteHandler = () => {
    navigate("/signup");
  };

  const getActiveForm = (data) => {
    const keys = Object.keys(data);
    for (let i = keys.length - 1; i >= 5; i--) {
      if (data[keys[i]] === true) {
        return keys[i];
      }
    }
    return null;
  };

  const transformApiResponse = (apiResponse, order) => {
    console.log(apiResponse, order);
    if (!apiResponse || !apiResponse.user) {
      throw new Error("Invalid API response format.");
    }

    const reorderedUser = {};

    order.forEach((key) => {
      if (key in apiResponse.user) {
        reorderedUser[key] = apiResponse.user[key];
      }
    });

    Object.keys(apiResponse.user).forEach((key) => {
      if (!(key in reorderedUser)) {
        reorderedUser[key] = apiResponse.user[key];
      }
    });

    return {
      jwt: apiResponse.jwt,
      user: reorderedUser,
    };
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);
    const data = {
      identifier: email,
      password: password,
    };
    loginService(data).then((res) => {
      if (res.status === 200) {
        setLoading(false);
        const riseFormationAddressItem = res.data.user.cartDetails.find(
          (item) => item.name === "RiseFormations Address"
        );
        const transformedResponse = transformApiResponse(res?.data, order);
        localStorage.setItem("token", res?.data?.jwt);
        localStorage.setItem("username", res?.data?.user?.username);
        localStorage.setItem("useremail", res?.data?.user?.email);
        localStorage.setItem("userId", res?.data?.user?.id);
        const activeForm = getActiveForm(transformedResponse?.user);
        localStorage.setItem("activeForm", activeForm);
        if (riseFormationAddressItem) {
          navigate("/company", { state: { riseFormationAddressItem } });
        } else {
          navigate("/company");
        }
      } else {
        setError("Something went wrong, please try again");
      }
      setLoading(false);
    });
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      width="100%"
      sx={{
        backgroundColor: "#F6F5F8",
        color: "#2F2D3B",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{
          maxWidth: "1440px",
          width: "100%",
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          sx={{
            maxWidth: "726px",
            width: "100%",
          }}
        >
          <Box
            component="form"
            display="flex"
            flexDirection="column"
            sx={{
              borderRadius: "10px",
              padding: "3.5rem",
              maxWidth: "726px",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                fontWeight: "700",
                fontSize: "2rem",
                marginBottom: "3rem",
                color: "#0F0049",
              }}
            >
              Sign in to your account
            </Typography>
            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            )}
            <Box sx={{ width: "100%", mb: 2 }}>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: "700",
                  mb: 2,
                  fontSize: "1.25rem",

                  color: "#0F0049",
                }}
              >
                Email
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                    borderColor: "#d3d3d3",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#d3d3d3",
                  },
                  mb: 2,
                  backgroundColor: "#FFFFFF",
                }}
              />
            </Box>
            <Box sx={{ width: "100%", mb: 2 }}>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: "700",
                  mb: 2,
                  fontSize: "1.25rem",
                  color: "#0F0049",
                }}
              >
                Password
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                    borderColor: "#d3d3d3",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#d3d3d3",
                  },
                  backgroundColor: "#FFFFFF",
                }}
              />
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: "100%", my: 2 }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                  />
                }
                label="Stay signed in for a week"
              />
              <Link href="#" underline="none">
                Forgot your password?
              </Link>
            </Box>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
              sx={{
                mt: 2,
                backgroundColor: "#2069F8",
                color: "#FFFFFF",
                "&:hover": {
                  backgroundColor: "#181e54",
                },
              }}
              onClick={handleSubmit}
            >
              {loading ? "Loading..." : "Continue"}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginPage;
