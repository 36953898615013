import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import BackButton from "../../assets/logo/BackButton.svg";

const ChooseBankAccount = ({ handleClickSetFour, handlePrevious }) => {
  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (newSelection) => {
    if (newSelection !== null) {
      setSelectedOption(newSelection);
      handleClickSetFour(newSelection);
    }
  };

  const handleContinue = () => {
    // Logic to handle the next step (e.g., navigate to next page)
    // if (onNext) onNext();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        textAlign: "left",
        alignItems: "center",
        padding: { xs: "0", md: "5rem" },
        paddingTop: { xs: "1rem" },
        maxWidth: { xs: "450px", md: "900px" },
      }}
    >
      {/* <Box
            sx={{
              display: "flex",
              width: "100%",
              maxWidth: "70vw",
              mb: 4,
            }}
          >
            <img
              src="https://path-to-your-logo.com/logo.png"
              alt="Rise Formations Logo"
              style={{ width: "150px", marginRight: "1rem" }}
            />
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold", color: "#3D3D3D" }}
            >
              RiseFormations
            </Typography>
          </Box> */}

      {/* Main Heading */}
      <Box>
        <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
          Would you like help choosing the right Business Bank Account?
        </Typography>

        {/* Subtitle */}
        <Typography
          variant="body1"
          sx={{
            fontSize: "1.2rem",
            color: "#5A5A5A",
            mb: 4,
          }}
        >
          We work with some of the largest high-street & challenger business
          banks in the UK including Monzo, Starling, Barclays, HSBC, Natwest &
          many more. ​ ​<br />
          Receive up to £100 cashback when you sign up through RiseFormations.​
        </Typography>

        {/* Options (ToggleButtonGroup) */}
        <Button
          variant="outlined"
          onClick={() => handleOptionChange("useRiseFormations")}
          sx={{
            border: "2px solid",
            borderColor:
              selectedOption === "useRiseFormations" ? "#10182C" : "#D1D1D1",
            borderRadius: "8px",
            fontSize: "1rem",
            padding: "0.9rem",
            // fontWeight: "bold",
            color:
              selectedOption === "useRiseFormations" ? "#10182C" : "#5A5A5A",
            marginBottom: "1rem",
            textTransform: "none",
            width: { xs: "19rem", md: "37rem" },
            "&:hover": {
              backgroundColor: "#181e54",
              color: "white",
            },
          }}
        >
          Yes, I’d like access to Business Bank support
        </Button>
        <br />
        {/* Option 2: Decide Later */}
        <Button
          variant="outlined"
          onClick={() => handleOptionChange("useOwnAddress")}
          sx={{
            border: "2px solid",
            borderColor:
              selectedOption === "useOwnAddress" ? "#10182C" : "#D1D1D1",
            borderRadius: "8px",
            fontSize: "1rem",
            // fontWeight: "bold",
            padding: "0.9rem",
            color: selectedOption === "useOwnAddress" ? "#10182C" : "#5A5A5A",
            textTransform: "none",
            width: { xs: "19rem", md: "37rem" },
            marginBottom: "2rem",
            "&:hover": {
              backgroundColor: "#181e54",
              color: "white",
            },
          }}
        >
          Decide later
        </Button>

        {/* Previous Button */}

        <Box display="flex">
          <Button
            onClick={handlePrevious}
            sx={{
              color: "#000",
              fontSize: "1rem",
              textTransform: "none",
              marginBottom: "0.75rem",
              marginRight: "0.75rem",
            }}
          >
            <img width={"70px"} src={BackButton} />
          </Button>

          {/* Continue Button */}
          {/* <Button
            variant="contained"
            sx={{
              backgroundColor: "#10182C",
              color: "#fff",
              borderRadius: "20px",
              // padding: "0.75rem 2rem",
              fontSize: "1.25rem",
              fontWeight: "bold",
              textTransform: "none",
              ":hover": {
                backgroundColor: "#5900CC",
              },
            }}
            onClick={() => handleClickSetFour(selectedOption)}
          >
            Continue
          </Button> */}
        </Box>
      </Box>
    </Box>
  );
};

export default ChooseBankAccount;
