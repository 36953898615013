import instance from "../instance";

export const getProfileService = async (id) => {
  try {
    const response = await instance.get(`/api/users/${id}?populate=role`);
    return response;
  } catch (error) {
    console.error("Error fetching customers:", error);
    return error;
  }
};

export const updateFormStatus = async (id, data) => {
  try {
    const response = await instance.put(
      `/api/users/${id}`,
      JSON.stringify(data)
    );
    return response;
  } catch (error) {
    console.error("Error fetching customers:", error);
    return error;
  }
};

export const getFormStatus = async (id) => {
  try {
    const response = await instance.get(`/api/users/${id}`);
    return response;
  } catch (error) {
    console.error("Error fetching customers:", error);
    return error;
  }
};
