import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import { Box } from "@mui/material";
import "../../styles/style.css";

const Layout = () => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      sx={{
        minHeight: "100vh",
        backgroundColor: "#F6F5F8",
        color: "#2F2D3B",
      }}
    >
      <Header />
      <Box
        component="main"
        sx={{
          width: "100%",
        }}
      >
        <Outlet />
      </Box>
      {/* <Footer /> */}
    </Box>
  );
};

export default Layout;
