import LocalizedStrings from "react-localization";

let strings = new LocalizedStrings({
  en: {
    homePage: {
      stepOneHeader: "Start your <br/> Company Formation",
      stepOneHeaderOnCompanySearch:
        "Congratulations! <br/> This company name is available.",
      stepOneHeaderOnCompanySearchNotAvailable:
        "Sorry! <br/> This company name is not available",
      stepOneSubHeader:
        "Over 1 million companies formed in the UK. With the Rise Group you have experience you can trust to get everything started.",
    },
    packageUI: {
      packageHeader: "Choose your company formation package",
      packageSubHeader:
        "Built with love for growing businesses. Check out the Saasimi pricing grid below. Switch between plans at any time.",
      serviceHeader: "Our Customers also bought:",
      serviceSubHeader:
        "Built with love for growing businesses. Check out the Saasimi pricing grid below. Switch between plans at any time.",
      checkoutHeader: "Great! Now it’s time to checkout.",
    },
  },
});

export default strings;
