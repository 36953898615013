import { Provider } from 'react-redux';
import './styles/style.css'
import { store } from './store/Store';

import Router from './router/Router';

function App() {

  return (
    <Provider store={store}>
      <Router />
    </Provider>  
  );
}

export default App;
