import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Autocomplete,
} from "@mui/material";
import { sendOtp } from "../../apiServices/services/CheckoutServices";
import { getcountries } from "../../apiServices/services/CompanyServices";
import BackButton from "../../assets/logo/BackButton.svg";

const AccountCreationForm = ({ handleClickSetSeven, handlePrevious }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
  });

  const countryPhoneCodes = [
    { code: "+1", label: "United States", flag: "🇺🇸" },
    { code: "+44", label: "United Kingdom", flag: "🇬🇧" },
    { code: "+91", label: "India", flag: "🇮🇳" },
    { code: "+61", label: "Australia", flag: "🇦🇺" },
    // Add more country codes as needed
  ];

  const [countryCodes, setCountriesCode] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);

  const handleCountryChange = (event, newValue) => {
    if (newValue) {
      setSelectedCountry(newValue);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    const { firstName, lastName, email, phoneNumber, password } = formData;
    if (!firstName || !lastName || !email || !phoneNumber || !password) {
      alert("Please fill all the details before proceeding forward.");
      return;
    }
    const data = {
      mobileNumber: selectedCountry.code + formData.phoneNumber,
      additionalDetails: "Some additional information if needed",
    };
    const formDatas = {
      ...formData,
      phoneNumber: selectedCountry.code + formData.phoneNumber,
    };
    localStorage.setItem("registeredEmail", formData.email);
    sendOtp(data).then((res) => {
      if (res.status === 200) {
        console.log(res);
        handleClickSetSeven(formDatas);
      }
    });
  };

  useEffect(() => {
    const getcountries = async () => {
      const response = await fetch("https://restcountries.com/v3.1/all");
      const countries = await response.json();

      const countryCodes = countries.map((country) => {
        const iddRoot = country?.idd?.root || ""; // Check if `idd.root` exists
        const iddSuffix = country?.idd?.suffixes?.[0] || ""; // Check if the first suffix exists

        return {
          label: country.name.common,
          code: iddRoot + iddSuffix, // Concatenate root and suffix safely
          flag: country.flags?.png || country.flags?.svg, // Use PNG or SVG flag image
        };
      });

      setCountriesCode(countryCodes);
      setSelectedCountry(countryCodes[0]); // Set the first country as default
    };

    getcountries();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: { xs: "0", md: "5rem" },
        paddingTop: { xs: "1rem" },
        maxWidth: { xs: "450px", md: "900px" },
      }}
    >
      <Box>
        <Typography variant="h4" sx={{ fontWeight: "bold", mb: 4 }}>
          Create an account to check out
        </Typography>

        <Box sx={{ display: "flex", gap: "1rem", width: "100%", mb: 2 }}>
          <TextField
            label="First Name"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            sx={{ backgroundColor: "#fff", borderRadius: "18px" }}
          />
          <TextField
            label="Last Name"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            sx={{ backgroundColor: "#fff", borderRadius: "8px" }}
          />
        </Box>

        {/* Email Field */}
        <TextField
          label="Email Address"
          name="email"
          value={formData.email}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          type="email"
          sx={{ mb: 2, backgroundColor: "#fff", borderRadius: "8px" }}
        />

        <TextField
          label="Password"
          name="password"
          type="password"
          value={formData.password}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          sx={{ mb: 2, backgroundColor: "#fff", borderRadius: "8px" }}
        />

        <Box sx={{ display: "flex", alignItems: "center", mb: "2rem" }}>
          <Autocomplete
            options={countryCodes}
            getOptionLabel={(option) => `${option.label} (${option.code})`} // Label will not include the flag
            value={selectedCountry}
            onChange={handleCountryChange} // Update selected country on change
            isOptionEqualToValue={(option, value) =>
              option.code === value?.code
            } // Prevent value mismatch
            renderOption={(props, option) => (
              <li {...props}>
                <img
                  src={option.flag}
                  alt={option.label}
                  style={{ width: "20px", marginRight: "8px" }}
                />
                {option.label} ({option.code})
              </li>
            )}
            sx={{
              width: 250,
              mr: 2,
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
            renderInput={(params) => (
              <TextField {...params} label="Code" variant="outlined" />
            )}
          />

          <TextField
            label="Mobile Number"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            type="number"
            sx={{ backgroundColor: "#fff", borderRadius: "8px" }}
          />
        </Box>

        <Box sx={{ display: "flex", width: "100%" }}>
          <Button
            // variant="contained"
            onClick={handlePrevious}
            sx={{
              color: "#000",
              fontSize: "1rem",
              textTransform: "none",
              marginBottom: "0.75rem",
              marginRight: "1rem",
            }}
          >
            <img width={"70px"} src={BackButton} />
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{
              backgroundColor: "blue",
              borderRadius: "15px",
              fontSize: "1.25rem",
              fontWeight: "bold",
              textTransform: "none",
              marginBottom: "0.75rem",
              ":hover": {
                backgroundColor: "#181e54",
              },
            }}
          >
            Confirm
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AccountCreationForm;
